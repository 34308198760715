const ejs = require('ejs');
const i18n = (lang) => {
  let data = require(`./languages/${lang}.json`);
  if(data) {
    return {
      ...data,
      compile: (attr, context) => {
        let str = data[attr];
        if(!str) return '';
        let compiled = ejs.compile(str);
        return compiled({...data, ...context});
      }
    };
  } else {
    return {};
  }
};

export default i18n;
