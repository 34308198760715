import React, { Component } from 'react';
import { Modal, Button, Input, message } from 'antd';
import Parse from 'parse';

export default class SyncModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      loading: false,
      textInput: null
    }
  }

  open = () => {
    this.setState({ visible: true })
  }

  close = () => {
    this.setState({
      visible: false,
      textInput: null
    })
  }

  sync = () => {
    if(this.state.textInput) {
      this.setState({
        loading: true,
      }, () => {
        console.log('textInput', this.state.textInput)
        Parse.Cloud.run('Sync_One', {
          No: this.state.textInput.trim()
        })
        .then(result => {
          console.log('SYNC RESULT', result)
          if(result.status === 'success') {
            message.success('Tarea sincronizada!')
          } else {
            message.error('No se ha podido sincronizar!')
          }
          this.setState({
            visible: false,
            loading: false,
            textInput: null
          })
        })
        .catch(err => {
          console.log('SYNC ERROR', err)
          message.error('No se ha podido sincronizar!')
          this.setState({
            visible: false,
            loading: false,
            textInput: null
          })
        })
      })
    } else {
      message.error('Campo vacío!')
    }

  }

  render() {
    let self = this;
    const { visible, loading, textInput } = this.state;

    return (
      <div style={{float: 'left', marginLeft: 10}}>
        <Button icon="sync" onClick={this.open}>Sincronizar tarea</Button>
          <Modal
            visible={visible}
            title="Sincronizar tarea"
            onOk={this.handleOk}
            onCancel={this.close}
            footer={[
              <Button key="back" onClick={this.close}>
                Cerrar
              </Button>,
              <Button key="submit" type="primary" loading={loading} onClick={() => this.sync()}>
                {loading ? 'Sincronizando' : 'Sincronizar'}
              </Button>,
            ]}
          >
            <h3>Nº de pedido</h3>
            <Input ref="textInput" placeholder="Ej: PS900000..." value={textInput} onChange={(e, value) => {
                this.setState({ textInput: e.target.value })
              }}/>
          </Modal>
      </div>
    );
  }
}
