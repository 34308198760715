import React, { Component } from 'react';

export class BlankPage extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    return (
      <div>
        {this.props.children}
      </div>
    )
  }
}
