import { Component } from 'react';
import { Row, Col, Table, Button, Modal } from 'antd';
import Parse from 'parse';
import { CleanLayout } from '../../layout/CleanLayout';
import moment from 'moment';

const materialRequestStatus = (record) => {
  if (record.processed) return "Procesado"
  return "Pendiente"
}

const columns = () => {
  return [
    {
      title: 'Categoría',
      dataIndex: 'Category',
      sorter: true,
      render: (field, record) => record.article.category.name
    },
    {
      title: 'Artículo',
      dataIndex: 'article',
      sorter: true,
      render: (field, record) => record.article.name,
    },
    {
      title: 'Cantidad',
      dataIndex: 'quantity',
      sorter: true,
      render: (field, record) => record.quantity,
    },
    {
      title: 'Instalador',
      dataIndex: 'company',
      render: (field, record) => record.user.company,
      sorter: true
    },
    {
      title: 'Dirección',
      dataIndex: 'address',
      sorter: true,
      render: (field, record) => `${record.address || ""} ${record.postalCode || ""} ${record.city || ""} ${record.province || ""}`,
    },
    {
      title: 'Fecha',
      dataIndex: 'date',
      render: (field, record) => moment(record.createdAt).format('MM/DD/YYYY'),
      sorter: true,
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      render: (field, record) => materialRequestStatus(record),
      sorter: true,
    }
  ];
}

export class Materials extends Component {
  state = {
    search: null,
    filters: {},
    data: [],
    pagination: {
      pageSize: 25
    },
    selectedRowKeys: [],
    loading: false,
  };

  showUnprocessedRequestWarning = unprocessedRequestCounter => {
    let contentWarning = `${unprocessedRequestCounter} solicitudes de material no se han procesado por falta de stock`
    if (unprocessedRequestCounter === 1) {
      contentWarning = `${unprocessedRequestCounter} solicitud de material no se ha procesado por falta de stock`
    }
    Modal.warning({
      title: 'Solicitudes no procesadas',
      content: contentWarning
    });
  }

  processMaterialRequest = async () => {
    this.setState({ loading: true });
    let unprocessedRequestCounter = 0
    const { data, selectedRowKeys } = this.state
    const dbMaterials = data.filter(({ objectId }) => selectedRowKeys.includes(objectId)).map(({ ParseClass }) => ParseClass);
    const dbArticles = dbMaterials.map(({ attributes }) => attributes.article)

    const articles = dbArticles.reduce((previousValue, currentValue) => {
      return Object.assign(previousValue, { [currentValue.id]: currentValue });
    }, {});

    for (const material of dbMaterials) {
      const { quantity: materialQuantity, article: { id: articleId } } = material.attributes;
      const article = articles[articleId];

      if (materialQuantity <= article.attributes.quantity) {
        const modifyQuantity = article.attributes.quantity - materialQuantity;
        article.set("quantity", modifyQuantity);
        material.set("processed", true);
      } else {
        unprocessedRequestCounter += 1
      }
    }
    
    await Parse.Object.saveAll(dbArticles);
    await Parse.Object.saveAll(dbMaterials);

    this.fetch();

    if (unprocessedRequestCounter > 0) this.showUnprocessedRequestWarning(unprocessedRequestCounter)
    
    this.setState({
      selectedRowKeys: [],
      loading: false,
    });
  };

  onSelectChange = selectedRowKeys => this.setState({ selectedRowKeys });

  componentDidMount() {
    this.fetch();
  }

  onFilterChange(filter, value) {
    this.setState({
      filters: {
        ...this.state.filters,
        [filter]: value
      }
    });
  }

  clearFilters() {
    this.setState({
      filters: {}
    }, () => {
      this.fetch()
    });
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.fetch({
      pageSize: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
    });
  };

  fetch = async (params = {}) => {
    this.setState({ loading: true });
    const Material = Parse.Object.extend('Material');
    const query = new Parse.Query(Material);
    query.include("user");
    query.include("article")
    query.include("article.category")

    if (params.sortOrder === 'ascend') query.ascending(params.sortField);

    if (params.sortOrder === 'descend') query.descending(params.sortField);

    if (params.page > 1) query.skip(params.pageSize * (params.page - 1));

    query.limit(params.pageSize || this.state.pagination.pageSize);
    query.withCount();
    const response = await query.find();

    if (response) {
      let data = response.results.map(r => {
        return {
          ParseClass: r,
          ...r.toJSON()
        }
      });

      this.setState(prevState => ({
        data,
        loading: false,
        pagination: {
          ...prevState.pagination,
          total: data.count
        },
      }));
    }
  };

  saveFormRef = formRef => this.formRef = formRef;

  render() {
    const { I18N } = this.props;
    const { loading, selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      getCheckboxProps: (record) => ({
        disabled: record.processed === true,
      })
    };

    const hasSelected = selectedRowKeys.length > 0;

    const selectedRequests = (hasSelected) => {
      if (selectedRowKeys.length <= 1) return hasSelected ? `Seleccionada ${selectedRowKeys.length} petición de material` : ''
      return hasSelected ? `Seleccionadas ${selectedRowKeys.length} peticiones de material` : ''
    }

    return (
      <CleanLayout {...this.props} title={I18N.Home}>
        <Row>
          <Col span={24}>
            <div style={{ margin: "1rem 1.5rem" }}>
              <Button type="primary" onClick={this.processMaterialRequest} disabled={!hasSelected} loading={loading}>
                {loading ? "Procesando" : "Procesar"}
              </Button>
              <span style={{ marginLeft: 8 }}>
                {selectedRequests(hasSelected)}
              </span>
            </div>
            <div style={{ background: '#fff', margin: 24, padding: 0, minHeight: 280 }}>
              <Table
                columns={columns()}
                rowKey={record => record.objectId}
                dataSource={this.state.data}
                pagination={this.state.pagination}
                loading={this.state.loading}
                onChange={this.handleTableChange}
                rowSelection={rowSelection}
              />
            </div>
          </Col>
        </Row>
      </CleanLayout>
    )
  }
}
