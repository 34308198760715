import React, { Component } from 'react';
import { Calendar, Badge, Layout, Popover, Tag } from 'antd';
import { Link } from "react-router-dom";
import Parse from 'parse';
import { CleanLayout } from '../../layout/CleanLayout';
import moment from 'moment';
import { filter } from 'lodash';
import 'moment/locale/es';
import demoTasks from '../../mock/tasks';
moment.locale('es');

const { Content } = Layout;
console.log(demoTasks)
const locale_ES = {
  "lang": {
    "placeholder": "Seleccionar fecha",
    "rangePlaceholder": ["Fehca inicial", "Fecha final"],
    "today": "Hoy",
    "now": "Ahora",
    "backToToday": "Volver a hoy",
    "ok": "Ok",
    "clear": "Limpiar",
    "month": "Mes",
    "year": "Año",
    "timeSelect": "Seleccionar hora",
    "dateSelect": "Selecionar fecha",
    "monthSelect": "Elige un mes",
    "yearSelect": "Elige un año",
    "decadeSelect": "Elige una decada",
    "yearFormat": "YYYY",
    "dateFormat": "D/M/YYYY",
    "dayFormat": "D",
    "dateTimeFormat": "D/M/YYYY HH:mm:ss",
    "monthFormat": "MMMM",
    "monthBeforeYear": true,
    "previousMonth": "Mes anterior (PageUp)",
    "nextMonth": "Mes siguiente (PageDown)",
    "previousYear": "Último año (Control + left)",
    "nextYear": "Próximo año (Control + right)",
    "previousDecade": "Última década",
    "nextDecade": "Próxima decada",
    "previousCentury": "Últimos 100 años",
    "nextCentury": "Próximos 100 años"
  },
  "timePickerLocale": {
    "placeholder": "Selecionar hora"
  },
  "dateFormat": "YYYY-MM-DD",
  "dateTimeFormat": "YYYY-MM-DD HH:mm:ss",
  "weekFormat": "YYYY-wo",
  "monthFormat": "YYYY-MM"
};

export class AgendaPage extends Component  {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    }
  }
  componentDidMount() {
    this.fetch()
  }

  onChange() {
    this.fetch()
  }

  fetch = async (params = {}) => {
    const { user, isAdmin } = this.props;
    console.log('params:', this.state.filters);
    this.setState({ loading: true });
    const Task = Parse.Object.extend('Task');
    const query = new Parse.Query(Task);
    let Primagas_Id = user.get('Primagas_Id');
    query.equalTo('Resource_first_line', `R${Primagas_Id}`);
    query.include(['Tech']);
    query.limit(2000);
    query.withCount();
    const response = await query.find();
    if(response) {
      console.log(response)
      let data = response.results.map(r => {
        let task = r.toJSON();
        task.Planned_Service_Date_Day = task.Planned_Service_Date ? moment(task.Planned_Service_Date.iso).format('YYYY-MM-DD') : '';
        task.Planned_Service_Date_Month = task.Planned_Service_Date ? moment(task.Planned_Service_Date.iso).format('YYYY-MM') : '';
        return task;
      });
        console.log(data)
      let pagination = {
        ...this.state.pagination,
        total: response.count
      };
      this.setState({
        loading: false,
        data: data,
        pagination,
      });
    }
  };

  render() {
    const { I18N, user } = this.props;
    console.log('Agenda render', this);
    return (
      <CleanLayout {...this.props} title={I18N.Agenda}>
        <div style={{ background: '#fff', margin: 24, padding: 24, minHeight: 280 }}>
          <Calendar locale={locale_ES} dateCellRender={(date) => this.dateCellRender(date)} monthCellRender={(value) => this.monthCellRender(value)} onChange={(value) => this.onChange()} />
        </div>
      </CleanLayout>
    )
  }

  getListData(value) {
    let listData;
    switch (value.date()) {
      case 8:
        listData = [
          { type: 'warning', content: 'This is warning event.' },
          { type: 'success', content: 'This is usual event.' },
        ];
        break;
      case 10:
        listData = [
          { type: 'warning', content: 'This is warning event.' },
          { type: 'success', content: 'This is usual event.' },
          { type: 'error', content: 'This is error event.' },
        ];
        break;
      case 15:
        listData = [
          { type: 'warning', content: 'This is warning event' },
          { type: 'success', content: 'This is very long usual event。。....' },
          { type: 'error', content: 'This is error event 1.' },
          { type: 'error', content: 'This is error event 2.' },
          { type: 'error', content: 'This is error event 3.' },
          { type: 'error', content: 'This is error event 4.' },
        ];
        break;
      default:
    }
    return listData || [];
  }

  dateCellRender(value) {
    const listData = filter(this.state.data, { Planned_Service_Date_Day: value.format('YYYY-MM-DD') });
    return (
      <ul className="events">
        {listData.map(item => (
          <li key={item.No}>
          <Popover content={(<p>{item.Address}</p>)} title={item.Installation_No}>
            <Link to={`/task/${item.objectId}`}>
              <Tag>{item.Installation_No} ({item.City})</Tag>
            </Link>
          </Popover>
          </li>
        ))}
      </ul>
    );
  }

  getMonthData(value) {
    if (value.month() === 8) {
      return 1394;
    }
  }

  monthCellRender(value) {
    const listData = filter(this.state.data, { Planned_Service_Date_Month: value.format('YYYY-MM') });
    return (
      <ul className="events">
        {listData.map(item => (
          <li key={item.No}>
          <Popover content={(<p>{item.Address}</p>)} title={item.Installation_No}>
            <Link to={`/task/${item.objectId}`}>
              <Tag>{item.Installation_No} ({item.City})</Tag>
            </Link>
          </Popover>
          </li>
        ))}
      </ul>
    );
  }
}
