import { APP_VERSION } from '../common/config';
import Notifications from './notifications';

window.verifiedVersion = false;

window.notify = new Notifications();

var getJSON = function(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.setRequestHeader("Cache-Control", "max-age=0");
    xhr.responseType = 'json';
    xhr.onload = function() {
      var status = xhr.status;
      if (status == 200) {
        callback(null, xhr.response);
      } else {
        callback(status);
      }
    };
    xhr.send();
};

window.saveImageAsFile = (fileName, url) => {
  var toDataUrl = (url, callback) => {
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
        var reader = new FileReader();
        reader.onloadend = function() {
            callback(reader.result);
        }
        reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  };
  toDataUrl(url, (base64) => {
    var link = document.createElement('a');
    link.style = 'position: fixed; left -10000px;';
    link.href = base64;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
};

window.versionUpdateNotified = false;
window.checkVersion = () => {
  if(window.location.origin.indexOf('localhost') === -1) {
    //console.log('Checking for new version!')
    getJSON(`${window.location.origin}/manifest.json`, (err, manifest) => {
      if(!err) {
        //console.log(manifest);
        let updatesVersion = parseInt(APP_VERSION ? APP_VERSION.split('.').join('') : 0);
        let manifestVersion = parseInt(manifest.version.split('.').join(''));
        if(updatesVersion < manifestVersion && !window.versionUpdateNotified) {
          if(!window.verifiedVersion) {
            window.verifiedVersion = true;
            window.versionUpdateNotified = true;
            window.location.reload(true)
          } else {
            window.versionUpdateNotified = true;
            window.notify.versionUpdate(manifest.version);
          }
        }
      }
    });
  } else {
    return;
  }
};

// console.log('APP INITIALIZED!')
window.checkVersion();

setInterval(() => {
  window.checkVersion();
}, (60*1000));
