const GLOBALS = window.APP_GLOBALS ? window.APP_GLOBALS : {};
export const ENV = GLOBALS.ENV ? GLOBALS.ENV : "development";

let server_url = "https://prd.primagasdto.com/";
let site_url = "https://primagasdto-prd.bcninfoservices.com";

if (ENV === 'release') {
  server_url = "https://primagasdto.herokuapp.com";
  site_url = "https://primagasdto.bcninfoservices.com";
}
if (ENV === 'staging') {
  server_url = "https://dev.primagasdto-test.com/";
  site_url = "https://primagasdto-dev.bcninfoservices.com";
}

export const APP_VERSION = GLOBALS.APP_VERSION ? GLOBALS.APP_VERSION : "";
export const SITE_URL = site_url;
export const PARSE_SERVER_URL = server_url + "/parse";
export const PARSE_APP_ID = "primagasdto";
export const PARSE_JS_KEY = "KgNkRnTqWtYv2y5A7DaFcHfMhPkSpUrWuZw";
export const ADMIN_ROLE = 'mXd4oRh4PM';
export const TECH_ROLE = 'QDQsrvqz3q';

export default {
  ENV,
  APP_VERSION,
  SITE_URL,
  PARSE_APP_ID,
  PARSE_SERVER_URL,
  PARSE_JS_KEY,
  ADMIN_ROLE,
  TECH_ROLE
}
