import React, { Component } from 'react';
import { Layout } from 'antd';
import { APP_VERSION } from '../../common/config';

const { Footer } = Layout;

export class GlobalFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    return (
      <Footer style={{ textAlign: 'center', backgroundColor: '#fbfbfb' }}>
        Primagas ©{new Date().getFullYear()} (versión: {APP_VERSION})
      </Footer>
    )
  }
}
