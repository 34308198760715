import React, { Component } from 'react';
import { Layout, Menu, Icon, Button } from 'antd';
import { Link } from 'react-router-dom';
import SyncModal from '../SyncModal';

const { Header } = Layout;
const SubMenu = Menu.SubMenu;

export class GlobalHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  handleClick = (e) => {
    this.props.onMenuChange(e)
  }

  render() {
    const { I18N, menu, user, language, isAdmin, isVendor } = this.props;
    // console.log('GlobalHeader render', this);

    const languageName = (lang) => {
      if(lang === 'en') {
        return 'English'
      }
      if(lang === 'es') {
        return 'Español'
      }
    };

    return (
      <Header style={{backgroundColor: '#DF2B2A'}}>
        <div className="logo" />
        <Menu
          theme="dark"
          mode="horizontal"
          onClick={this.handleClick}
          defaultSelectedKeys={[this.state.currentPage]}
          style={{ backgroundColor: '#DF2B2A', lineHeight: '64px', float: 'left' }}
        >
          {menu.map(item => {
            if(item.children && item.children.length) {
              return (
                <SubMenu key={item.path} title={item.name}>
                  {item.children.map(subitem => (
                    <Menu.Item key={subitem.path}>
                      <Link to={subitem.path}>
                        {subitem.icon? <Icon type={subitem.icon} /> : null}
                        {subitem.name}
                      </Link>
                    </Menu.Item>))}
                </SubMenu>
              );
            } else {
              return (
                <Menu.Item key={item.path}>
                  <Link to={item.path}>
                    {item.icon? <Icon type={item.icon} /> : null}
                    {item.name}
                  </Link>
                </Menu.Item>
              );
            }
          })}
        </Menu>
        {isAdmin ? <SyncModal /> : null}
        <Menu
          theme="dark"
          mode="horizontal"
          onClick={this.handleClick}
          defaultSelectedKeys={[this.state.currentPage]}
          style={{ backgroundColor: '#DF2B2A', lineHeight: '64px', float: 'right', marginTop: '-1px' }}
        >
          <Menu.Item key="/apps/">
            <Link to="/apps/">
              <span style={{backgroundColor: 'transparent', color: '#000000', border: '#000000 solid 1px', borderRadius: '4px', padding: 10}}>Descargar Apps</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/logout/">Logout</Menu.Item>
        </Menu>
      </Header>
    )
  }
}
