import { Component } from 'react';
import { Row, Col, Table, Button, InputNumber } from 'antd';
import Parse from 'parse';
import { CleanLayout } from '../../layout/CleanLayout';


const columns = handelChange => {
  return [
    {
      title: 'Categoría',
      dataIndex: 'Category',
      sorter: true,
      render: (field, record) => record.category.name
    },
    {
      title: 'Artículo',
      dataIndex: 'article',
      sorter: true,
      render: (field, record) => record.name,
    },
    {
      title: 'Cantidad',
      dataIndex: 'quantity',
      sorter: true,
      render: (field, record) => <InputNumber min={0} max={99} defaultValue={record.quantity} onChange={value => handelChange(value, record.objectId)} />
    }
  ];
}

export class Stock extends Component {
  state = {
    visible: false,
    filters: {},
    data: [],
    pagination: {
      pageSize: 25
    },
    loading: false,
    stock: {}
  };

  handelChange = (value, id) => {
    this.setState(prevState => ({
      stock: {
        ...prevState.stock,
        [id]: value
      },
    }))
  }

  saveArticle = async () => {
    this.setState({ loading: true });
    const { data, stock } = this.state
    const  stockKeys = Object.keys(stock)
    const modifiedArticles = data.filter(({ objectId }) => stockKeys.includes(objectId)).map(({ ParseClass }) => ParseClass);

    for (const article of modifiedArticles) article.set("quantity", stock[article.id]);

    await Parse.Object.saveAll(modifiedArticles);
    this.fetch();

    this.setState({
      stock: {},
      loading: false,
    });
  }; 

  componentDidMount() {
    this.fetch();
  }

  onFilterChange(filter, value) {
    this.setState({
      filters: {
        ...this.state.filters,
        [filter]: value
      }
    });
  }

  clearFilters() {
    this.setState({
      filters: {}
    }, () => {
      this.fetch()
    });
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.fetch({
      pageSize: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
    });
  };

  fetch = async (params = {}) => {
    this.setState({ loading: true });
    const Article = Parse.Object.extend('Article');
    const query = new Parse.Query(Article);
    query.include("category")

    if (params.sortOrder === 'ascend') query.ascending(params.sortField);
    
    if (params.sortOrder === 'descend') query.descending(params.sortField);
    
    if (params.page > 1) query.skip(params.pageSize * (params.page - 1));
    
    query.limit(params.pageSize || this.state.pagination.pageSize);
    query.withCount();

    const response = await query.find();

    if (response) {
      let data = response.results.map(r => {
        return {
          ParseClass: r,
          ...r.toJSON()
        }
      }
      );

      this.setState(prevState => ({
        data,
        loading: false,
        pagination: {
          ...prevState.pagination,
          total: data.count
        },
      }));
    }
  };

  showModal = () => {
    this.setState({ visible: true });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  render() {
    const { I18N } = this.props;
    const { loading, stock } = this.state;

    const hasAnyChange = Object.keys(stock).length > 0;

    return (
      <CleanLayout {...this.props} title={I18N.Home}>
        <Row>
          <Col span={24}>
            <div style={{ margin: "1rem 1.5rem" }}>
              <Button type="primary" onClick={this.saveArticle} disabled={!hasAnyChange} loading={loading}>
                {loading ? "Guardando" : "Guardar"}
              </Button>
            </div>
            <div style={{ background: '#fff', margin: 24, padding: 0, minHeight: 280 }}>
              <Table
                columns={columns(this.handelChange)}
                rowKey={record => record.objectId}
                dataSource={this.state.data}
                pagination={this.state.pagination}
                loading={this.state.loading}
                onChange={this.handleTableChange}
              />
            </div>
          </Col>
        </Row>
      </CleanLayout>
    )
  }
}
