import React from 'react';
import moment from 'moment';
import { Button, notification } from 'antd';

const queryNotices = () => {
  return new Promise(resolve => {
    let notifications = localStorage.getItem('notifications');
    notifications = notifications ? JSON.parse(notifications) : [];
    resolve(notifications);
  });
};

const removeNotices = type => {
  return new Promise(resolve => {
    let notifications = localStorage.getItem('notifications');
    notifications = notifications ? JSON.parse(notifications) : [];
    var clearedList = [];
    notifications.forEach(item => {
      if (type === 'Avisos' && item.type !== 'notice') {
        clearedList.push(item);
      }
      if (type === 'Mensajes' && item.type !== 'messages') {
        clearedList.push(item);
      }
    });
    localStorage.setItem('notifications', JSON.stringify(clearedList));
    resolve(clearedList);
  });
};

const makeNotification = ({title, description}) => {
  notification.info({
    message: title,
    description: description,
  });
};

export default class Notifications {
  constructor() {
    this.subscription = null;
  }

  open({ title, description }) {
    let notifications = localStorage.getItem('notifications');
    notifications = notifications ? JSON.parse(notifications) : [];
    notifications.push({
      id: moment().format('YYYYMMDDHHmmss'),
      title,
      description,
      status: 'processing',
      type: 'notice',
      datetime: moment()._d,
    });
    localStorage.setItem('notifications', JSON.stringify(notifications));
    makeNotification({ title, description })
  }

  versionUpdate(version) {
    notification.warning({
      message: `Hay una nueva actualización (${version})`,
      description: (
        <div>
          Haz click en recargar para finalizar. <a onClick={() => window.location.reload(true)}>Recargar</a>
        </div>
      ),
      placement: 'topRight',
      duration: 0
    });
  }
}
