import React, { Component } from 'react';
import Parse from 'parse';
import { Row, Col, Card, Form, Icon, Input, Button, Divider, message } from 'antd';
import { Link } from "react-router-dom";
import { BlankPage } from '../../layout/BlankPage/BlankPage';
import logo from '../../assets/images/primagas_logo.png';

import './login.css';

const success = () => {
  message.success('Usuario correcto. Espera un segundo...');
};

const error = (text) => {
  message.error(text);
};

class NormalLoginForm extends React.Component {
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { username, password } = values;
        Parse.User.logIn(username, password)
        .then(result => {
          success();
          window.location.href = window.location.origin;
        })
        .catch(err => error('Usuario o contraseña incorrecto!'));
      } else {
        error('Rellena los datos de acceso!');
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit} className="login-form">
        <Form.Item>
          {getFieldDecorator('username', {
            rules: [{ required: true, message: 'Por favor, introduce tu email!' }],
          })(
            <Input
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Email"
            />,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Por favor, introduce tu contraseña!' }],
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Contraseña"
            />,
          )}
        </Form.Item>
        <Form.Item>
          <Button block htmlType="submit">
            Log in
          </Button>
          <a href="/recovery/">¿Has olvidado tu contraseña?</a>
        </Form.Item>
      </Form>
    );
  }
}

const WrappedNormalLoginForm = Form.create({ name: 'normal_login' })(NormalLoginForm);

export class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
  console.log('Login render', this);
    const { I18N, user } = this.props;
    return (
      <BlankPage {...this.props}>
        <Row>
          <Col xs={{ span: 1 }} lg={{ span: 9 }}></Col>
          <Col xs={{ span: 22 }} lg={{ span: 6 }} className="login-wrapper">
            <Card className="login-container">
              <img src={logo} width="100%"/>
              <Divider/>
              <WrappedNormalLoginForm />
            </Card>
          </Col>
          <Col xs={{ span: 1 }} lg={{ span: 9 }}></Col>
        </Row>
      </BlankPage>
    )
  }
}
