import React, { Component, useState, useEffect } from 'react';
import { Layout, Row, Col, Divider, Table, Button, Select, DatePicker, Input, Badge, Drawer, Dropdown, Icon, Menu, message, Modal } from 'antd';
import { Link } from "react-router-dom";
import Parse from 'parse';
import moment from 'moment';
import { find } from 'lodash';
import { CleanLayout } from '../../layout/CleanLayout';
import { Status_List } from '../../utils/utils';
import TaskExporter from '../../components/TaskExporter';
import NoteModal from '../../components/NoteModal/NoteModal'

const getUrlParams = () => {
  var search = window.location.search.substring(1);
  let params = search !== '' ? JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}') : {};
  Object.keys(params).forEach(key => {
    if(key === 'Planned_Service_Date' || key === 'New_Service_Date') {
      params[key] = moment(decodeURIComponent(params[key]));
    } else {
      params[key] = decodeURIComponent(params[key]);
    }
  })
  return params;
}

const { Search } = Input;

const searchFieldsMap = {
  Installation_No: 'Número de instalación',
  No: 'Número de pedido',
  Resource_first_line: 'Empresa instaladora'
}

const FetchTechs = async () => {
  const Role = Parse.Object.extend('_Role');
  const roleQuery = new Parse.Query(Role);
  roleQuery.equalTo('name', 'tech');
  let role = await roleQuery.first();
  //console.log('role', role);
  let users = [];
  if(role) {
    let usersQuery = role.getUsers().query();
    let results = await usersQuery.find();
    users = results.map(r => r.toJSON())
  }
  return users;
};

const FetchFilters = async ({user = null, isAdmin = false}) => {
  const Task = Parse.Object.extend('Task');
  const query = new Parse.Query(Task);
  query.select(['Service_Order_Type', 'City']);
  if(!isAdmin) {
    let Primagas_Id = user.get('Primagas_Id');
    query.equalTo('Resource_first_line', `R${Primagas_Id}`);
  }
  query.limit(7000);
  let items = await query.find()
  .then(results => results.map(result => {
    return {
      Service_Order_Type: result.get('Service_Order_Type'),
      City: result.get('City')
    }
  }))
  .catch(err => []);
  let types = [];
  let localities = [];
  items.forEach(item => {
    if(types.indexOf(item.Service_Order_Type) === -1) {
      types.push(item.Service_Order_Type);
    }
    if(localities.indexOf(item.City) === -1) {
      localities.push(item.City);
    }
  });
  localities.sort(function(a, b){
    if(a < b) { return -1; }
    if(a > b) { return 1; }
    return 0;
  })
  types.sort(function(a, b){
    if(a < b) { return -1; }
    if(a > b) { return 1; }
    return 0;
  })
  return {types, localities};
}

const { Content } = Layout;
const { Option } = Select;

let defaultFilters = getUrlParams();


const Filters = (props) => {
  const onFilterChange = (filter, value, keepInUrl = false) => {
    let newFilters = {
      ...props.filters,
      [filter]: value !== '' ? value : null
    }
    props.updateFilters(newFilters)
  }

  return (
    <Drawer
      title="Filtrado de datos"
      placement="right"
      closable={true}
      width={420}
      onClose={() => props.onClose()}
      visible={props.visible}
    >
      <div className="filters">
        <Row>
          <Col span={24}>
            <div className="filters-item">
              <span className="title">Tipo de tarea</span>
              <Select
                showSearch
                placeholder="Selecionar tipo"
                optionFilterProp="children"
                value={props.filters['Service_Order_Type']}
                onChange={(value) => onFilterChange('Service_Order_Type', value, true)}
                style={{width: '100%' }}
              >
                {props.types.map(type => (
                  <Option key={type} value={type}>{type}</Option>
                ))}
              </Select>
            </div>
          </Col>
          <Col span={24}>
            <Divider />
          </Col>
          <Col span={24}>
            <div className="filters-item">
              <span className="title">Población</span>
              <Select
                showSearch
                placeholder="Selecionar población"
                optionFilterProp="children"
                value={props.filters['City']}
                onChange={(value) => onFilterChange('City', value, true)}
                style={{width: '100%' }}
              >
                {props.localities.map(locality => (
                  <Option key={locality} value={locality}>{locality}</Option>
                ))}
              </Select>
            </div>
          </Col>
          <Col span={24}>
            <Divider />
          </Col>
          <Col span={24}>
            <div className="filters-item">
              <span className="title">Estado</span>
              <Select
                showSearch
                placeholder="Seleciona un estado"
                optionFilterProp="children"
                value={props.filters['Status']}
                onChange={(value) => onFilterChange('Status', value, true)}
                style={{width: '100%' }}
              >
                {Status_List.map(status => (
                  <Option key={status.id} value={status.id}>{status.name}</Option>
                ))}
              </Select>
            </div>
          </Col>
          <Col span={24}>
            <Divider />
          </Col>
          <Col span={24}>
            <div className="filters-item">
              <span className="title">Fecha prevista</span>
              <DatePicker
                placeholder="Selecionar fecha"
                value={props.filters['Planned_Service_Date']}
                onChange={(value) => onFilterChange('Planned_Service_Date', value, true)}
                style={{width: '100%' }}
              />
            </div>
          </Col>
          <Col span={24}>
            <Divider />
          </Col>
          <Col span={24}>
            <div className="filters-item">
              <span className="title">Fecha otorgada</span>
              <DatePicker
                placeholder="Selecionar fecha"
                value={props.filters['New_Service_Date']}
                onChange={(value) => onFilterChange('New_Service_Date', value, true)}
                style={{width: '100%' }}
              />
            </div>
          </Col>
          <Col span={24}>
            <Divider />
          </Col>
          <Col span={24}>
            <Button
              type="primary"
              onClick={() => {
                props.onFilter()
              }}
            >
              Filtrar pedidos  
            </Button>
          </Col>
        </Row>
      </div>
    </Drawer>
  );
}

export class HomePage extends Component {
  state = {
    initialDataLoaded: false,
    isFetching: false,
    visibleFilters: false,
    search: null,
    searchText: null,
    searchField: 'Installation_No',
    filters: defaultFilters ? defaultFilters : {},
    data: [],
    pagination: {
      pageSize: 25
    },
    loading: false,
    types: [],
    localities: [],
    techs: []
  };

  async componentDidMount() {
    if(!this.state.isFetching) {
      this.fetch();
    }
  }

  fetchInitialData() {
    const { user, isAdmin } = this.props;
    return new Promise(async resolve => {
      const { types, localities } = await FetchFilters({ user, isAdmin });
      let techs = [];
      if (this.props.isAdmin) {
        techs = await FetchTechs()
      }
      resolve({
        techs,
        types,
        localities,
        initialDataLoaded: true
      })
    });    
  }

  clearFilters() {
    var newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname;
    window.history.pushState({ id: window.location.pathname }, "another page", newUrl);
    this.setState({
      filters: {},
      search: null,
      searchField: 'Installation_No',
      searchText: null,
    }, () => {
      setTimeout(() => document.querySelectorAll('.searchInput input').forEach(el => el.value = null), 300)
      this.fetch()
    });
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.fetch({
      pageSize: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
    });
  };

  fetch = async (params = {}) => {
    const { user, isAdmin } = this.props;
    // console.log('fetch', this.state)
    this.setState({
      loading: true,
      isFetching: true
    }, async () => {

      const Task = Parse.Object.extend('Task');
      const query = new Parse.Query(Task);
      if (!isAdmin) {
        let Primagas_Id = user.get('Primagas_Id');
        query.equalTo('Resource_first_line', `R${Primagas_Id}`);
      }

      if (this.state.searchField && this.state.searchText) {
        let parsedValue = this.state.searchText.trim();
        query.equalTo(this.state.searchField, parsedValue);
        // if (this.state.searchField === 'Resource_first_line') {
        //   parsedValue = parsedValue.replace('R', '');
        // }
        // query.matches(field, `.*${parsedValue}*.`);
        // query.fullText(field, value, { language: 'es', caseSensitive: false, diacriticSensitive: false });
      }


      Object.keys(this.state.filters).forEach(key => {
        if (key === 'No') {
          // query.matches(key, `.*${this.state.filters[key]}*.`);
          query.equalTo(key, this.state.filters[key].trim());
        }
        if (key === 'Installation_No') {
          // query.matches(key, `.*${this.state.filters[key]}*.`);
          query.equalTo(key, this.state.filters[key].trim());
        }
        if (key === 'Resource_first_line') {
          // query.matches(key, `.*${this.state.filters[key]}*.`);
          query.equalTo(key, this.state.filters[key].trim());
        }
        if (key === 'Service_Order_Type') {
          query.equalTo(key, this.state.filters[key]);
        }
        if (key === 'Status') {
          query.equalTo(key, this.state.filters[key]);
        }
        if (key === 'City') {
          query.equalTo(key, this.state.filters[key]);
        }
        if (key === 'Planned_Service_Date') {
          if (this.state.filters[key]) {
            query.greaterThanOrEqualTo(key, new Date(this.state.filters[key].format('YYYY-MM-DD 00:00')));
            query.lessThanOrEqualTo(key, new Date(this.state.filters[key].format('YYYY-MM-DD 23:59')));
          }
        }
        if (key === 'New_Service_Date') {
          if (this.state.filters[key]) {
            query.greaterThanOrEqualTo(key, new Date(this.state.filters[key].format('YYYY-MM-DD 00:00')));
            query.lessThanOrEqualTo(key, new Date(this.state.filters[key].format('YYYY-MM-DD 23:59')));
          }
        }
        if (key === 'Tech') {
          query.equalTo(key, { __type: 'Pointer', className: '_User', objectId: this.state.filters[key] });
        }
      });
      if (params.sortOrder === 'ascend') {
        query.ascending(params.sortField);
      }
      if (params.sortOrder === 'descend') {
        query.descending(params.sortField);
      }
      if (params.page > 1) {
        query.skip(params.pageSize * (params.page - 1));
      }
      query.include(['Tech']);
      query.limit(params.pageSize || this.state.pagination.pageSize);
      query.withCount();
      const response = await query.find();
      if (response) {
        // console.log(response)
        let data = response.results.map(r => {
          return {
            ParseObject: r,
            ...r.toJSON()
          }
        });
        // console.log(data)
        let pagination = {
          ...this.state.pagination,
          total: response.count
        };
        let presets = {}; 
        if (!this.state.initialDataLoaded) {
          presets = await this.fetchInitialData();
        }
        this.setState({
          ...presets,
          loading: false,
          data: data,
          pagination,
          isFetching: false
        });
      }

    });
    
  };

  deleteTask = (e, task) => {
    e.preventDefault()
    let self = this;
    const { isAdmin } = this.props;
    if(!isAdmin) return;
    Modal.confirm({
      title: `¿Eliminar la tarea ${task.No}?`,
      content: 'La tarea no se podrá recuperar.',
      okText: 'Eliminar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk() {
        task.ParseObject.destroy()
        .then(destroyed => {
          let newData = [];
          self.state.data.forEach(item => {
            if(item.objectId !== task.objectId) {
              newData.push(item);
            }
          });
          self.setState({
            data: newData
          }, () => {
            message.success(`Tarea ${task.No} eliminada.`)
          });
        })
        .catch(err => {
          message.error(`Error eliminando la tarea ${task.No}.`)
        });
      },
    });

  };

  render() {
    const { I18N, user, isAdmin, isVendor } = this.props;
    const { currentPage, loading, techs } = this.state;
    // console.log('Home render', this);

    const hasFilters = Object.keys(this.state.filters).length;

    const columns = [];
    columns.push({
      title: 'Nº Instalación',
      dataIndex: 'Installation_No',
      sorter: true,
      render: (field, record) => (<Link to={`/task/${record.objectId}`}>{field}</Link>),
      width: '10%',
    });
    columns.push({
      title: 'Nº Pedido',
      dataIndex: 'No',
      sorter: true,
      render: (field, record) => (<Link to={`/task/${record.objectId}`}>{field}</Link>),
      width: '10%',
    });
    if(isAdmin) {
      columns.push({
        title: 'Empresa instaladora',
        dataIndex: 'Resource_first_line',
        sorter: true,
        render: (field, record) => (<Link to={`/task/${record.objectId}`}>{field}</Link>),
        width: '10%',
      });
    }
    columns.push({
      title: 'Tipo',
      dataIndex: 'Service_Order_Type',
      sorter: true,
      render: (field, record) => field,
      width: '10%',
    });
    columns.push({
      title: 'Cliente',
      dataIndex: 'Name',
      render: (field, record) => field,
      sorter: true,
      width: '15%',
    });
    columns.push({
      title: 'Ciudad',
      dataIndex: 'City',
      render: (field, record) => field,
      sorter: true,
    });
    columns.push({
      title: 'Fecha prevista',
      dataIndex: 'Planned_Service_Date',
      render: (field, record) => record.Planned_Service_Date ? moment(record.Planned_Service_Date.iso).format('DD/MM/YYYY') : '...',
      sorter: true,
    });
    columns.push({
      title: 'Fecha otorgada',
      dataIndex: 'New_Service_Date',
      render: (field, record) => record.New_Service_Date ? moment(record.New_Service_Date.iso).format('DD/MM/YYYY') : '...',
      sorter: true,
    });
    columns.push({
      title: 'Estado',
      dataIndex: 'Status',
      width: '7%',
      render: (field, record) => {
        const status = find(Status_List, {id: record.Status})
        return (
          <div>
            <Badge color={status ? status.color : 'purple'} text={status ? status.name : '...'} />
          </div>
        );
      },
      sorter: true,
    });
    if(isAdmin) {
      columns.push({
        title: 'Acciones',
        dataIndex: 'actions',
        width: '8%',
        render: (field, record) => {
            const menu = (
              <Menu>
                <Menu.Item>
                  <Link to={`/task/${record.objectId}`}>
                    Ver tarea
                  </Link>
                </Menu.Item>
                <Menu.Item >
                  <NoteModal parent={record} user={this.props.user}/>
                </Menu.Item>
                <Menu.Item>
                  <a
                    onClick={(e) => this.deleteTask(e, record)}
                    rel="noopener noreferrer"
                  >
                    Eliminar
                  </a>
                </Menu.Item>
              </Menu>
            );
          return (
            <Dropdown overlay={menu}>
              <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                Acciones <Icon type="down" />
              </a>
            </Dropdown>
          );
        },
        sorter: true,
      });
    }

    const selectBefore = (
      <Select value={this.state.searchField} onChange={value => {
        setTimeout(() => document.querySelectorAll('.searchInput input').forEach(el => el.value = null), 300)
        this.setState({ searchField: value })
      }} style={{ width: 180 }}>
        {Object.keys(searchFieldsMap).map(key => <Option key={key} value={key}>{searchFieldsMap[key]}</Option>)}
      </Select>
    );

    return (
      <CleanLayout {...this.props} title={I18N.Home}>
        {this.state.searchText ? (
          <div style={{ margin: '0 24px', padding: 0 }}>
                <div style={{ fontWeight: 400, padding: '10px 5px' }}>
                  Mostrando resultados de búsqueda para el {searchFieldsMap[this.state.searchField].toLowerCase()} <a>{this.state.searchText}</a>
                </div>
            </div>
        ) : null}
        <div style={{ margin: '0 24px', padding: 0 }}>
          <Row>
            <Col span={12} style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <div className="filters-item">
                <Search addonBefore={selectBefore} className="searchInput" placeholder={`Buscar por ${searchFieldsMap[this.state.searchField].toLowerCase()}`} onSearch={value => {
                  this.setState({ searchText: value }, () => {
                    this.fetch()
                  })
                }} enterButton style={{minWidth: 520}} />
              </div>
            </Col>
            <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div className="button-item">
                <Button icon="filter" onClick={() => this.setState({ visibleFilters: true })} type={hasFilters > 0 ? 'danger' : 'default'}>{hasFilters > 0 ? 'Filtros (' + hasFilters + ')' : 'Filtros'}</Button>
                <Filters
                  filters={this.state.filters}
                  localities={this.state.localities}
                  types={this.state.types}
                  visible={this.state.visibleFilters}
                  updateFilters={(filters) => {
                    var newParameters = '';
                    Object.keys(filters).forEach((key, i) => {
                      if (filters[key]) {
                        if (i === 0) {
                          newParameters += `?${key}=${filters[key]}`
                        } else {
                          newParameters += `&${key}=${filters[key]}`
                        }
                      }
                    })
                    var newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + newParameters;
                    // console.log(newUrl);
                    window.history.pushState({ id: window.location.pathname }, "another page", newUrl);

                    // console.log('onFilter', filters)
                    this.setState({ filters })
                  }}
                  onFilter={() => {
                    // console.log('onFilter', filters)
                    this.setState({ visibleFilters: false }, () => {
                      this.fetch()
                    })
                  }}
                  onClose={() => this.setState({ visibleFilters: false })}
                />
              </div>
              <div className="button-item">
                <Button icon="reload" onClick={() => this.clearFilters()}>Reiniciar</Button>

              </div>
              <div className="button-item">
                <TaskExporter {...this.props} />

              </div>
            </Col>
          </Row>
        </div>
        
        <div style={{ background: '#fff', margin: '0 24px', padding: 0, minHeight: '80vh' }}>
            <Table
              sticky
              columns={columns}
              rowKey={record => record.objectId}
              dataSource={this.state.data}
              pagination={this.state.pagination}
              loading={this.state.loading}
              onChange={this.handleTableChange}
            />
        </div>
      </CleanLayout>
    )
  }
}
