import React, { Component } from "react";
import { Modal, Button, Input, message } from "antd";
import Parse from "parse";

export default class NoteModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      loading: false,
      note: null,
      textInput: null,
      isEditMode: false,
    }
  }

  open = async () => {
    let retrievedNote = await this.retrieveNoteFromDB();
    let isEditMode = true;

    if(!retrievedNote) {
      isEditMode = false;
      retrievedNote = this.createNewNote()
    }

    this.setState({
      visible: true,
      note: retrievedNote,
      isEditMode,
      textInput: retrievedNote?.attributes?.text || null
    })
  }

  close = () => {
    this.setState({
      visible: false,
      note: null
    })
  }

  saveNoteField = async () => {
    try {
      this.setState({
        loading: true
      });

      const {note, textInput} = this.state;
      note.set("text", textInput);
      await note.save();
      this.setState({
        visible: false,
      }, () => message.success("Los cambios se han realizado satisfactoriamente."))
    } catch (err) {
      console.log("Error creating note: ", err);
      message.error("Error al realizar los cambios.")
    } finally {
      this.setState({
        loading: false
      })
    }
  }

  launchDeleteConfirmation = (onOk) => {
    Modal.confirm({
      title: `¿Quieres realmente eliminar la nota ?`,
      content: "La nota no se podrá recuperar.",
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk,
    })
  }

  deleteNoteField = (e, note) => {
    e.preventDefault();

    this.launchDeleteConfirmation(async () => {
      try {
        await note.destroy();
        this.setState({
          visible: false,
          note: null
        }, () => message.success("La nota ha sido eliminada."));

      } catch (err) {
        console.error(err);
        message.error("Error eliminando la nota.")
      }
    })
  }

  retrieveNoteFromDB = () => {
    const { parent } = this.props;
    const taskId = parent.objectId;
    const Note = Parse.Object.extend("Note");
    const query = new Parse.Query(Note);
    query.equalTo("task", {
      __type: "Pointer",
      className: "Task",
      objectId: taskId,
    })

    return query
      .first()
      .then((data) => data || null)
      .catch((err) => {
        console.log("error retrieving note: ", err);
        return null;
      })
  }

  createNewNote() {
    const {
      parent: { ParseObject: task },
      user
    } = this.props;
    const Note = Parse.Object.extend("Note");
    const newNote = new Note();

    newNote.set("text", "");
    newNote.set("task", task);
    newNote.set("owner", user);

    return newNote
  }

  
  handleChange = (e) => {
    const inputValue = e.target.value;
    this.setState((prevState) => {
      return { ...prevState, textInput: inputValue}
    })
  }

  render() {
    const { visible, loading, note, isEditMode, textInput } = this.state;

    const buttonsFooter = (isEditMode) => {
      const buttons = [
        <Button key="back" onClick={this.close}>
          Cerrar
        </Button>
      ];

      if (isEditMode) {
        buttons.push(
          <Button
            key="delete"
            type="primary"
            loading={loading}
            onClick={(e) => this.deleteNoteField(e, note)}
          >
            {loading ? "Eliminando" : "Eliminar"}
          </Button>
        );

        buttons.push(
          <Button
            key="edit"
            type="primary"
            disabled={!textInput || textInput === note?.attributes?.text}
            loading={loading}
            onClick={this.saveNoteField}
          >
            {loading ? "Editando" : "Editar"}
          </Button>
        )
      } else {
        buttons.push(
          <Button
            key="submit"
            type="primary"
            disabled={!!!textInput}
            loading={loading}
            onClick={this.saveNoteField}
          >
            {loading ? "Creando" : "Crear"}
          </Button>
        );
      }
      return buttons
    }

    return (
      <>
        <Button
          style={{ color: "rgba(0, 0, 0, 0.65)", padding: "0", height: "auto" }}
          type="link"
          onClick={this.open}
        >
          Gestionar nota
        </Button>
        <Modal
          visible={visible}
          title="Gestionar nota"
          onOk={this.handleOk}
          onCancel={this.close}
          footer={buttonsFooter(isEditMode)}
        >
          <h3>Nota</h3>
          <Input.TextArea
            rows={5}
            ref="textInput"
            placeholder="Escribe una nota..."
            value={textInput}
            onChange={this.handleChange}
          />
        </Modal>
      </>
    );
  }
}
