import React from 'react';
import { Icon, Button, Modal, Form, Input, InputNumber, Checkbox, Select, DatePicker, Upload, Divider, message } from 'antd';
import { find } from 'lodash';
import moment from 'moment';
import { stringToJs } from '../../../utils/utils';

const { Option } = Select;

const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('Solo puedes subir archivos JPG/PNG!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

const SchemaFieldForm = Form.create({ name: 'form_in_modal' })(
  // eslint-disable-next-line
  class extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        loaded: false,
        submitting: false,
      }
    }

    visibility(field) {
      const { fields = [], taskData } = this.props;
      const { getFieldsValue } = this.props.form;
      //console.log('visibleCondition', field.visible);
      if(field.visible) {
        let fn = new Function("values", `let op = ${field.visible}; return op;`);
        let values = {
          ...getFieldsValue(fields.length ? fields.map(f => f.id) : [])
        };
        let result = fn(values);
        if(result === true || result === false) {
          return result;
        }
        return true;
      }
      return true;
    }

    rewriteString(str) {
      //console.log('str', str)
      const {
        task,
      } = this.props;
      if(str && typeof str === 'string' && str.indexOf('{{') !== -1 && str.indexOf('}}') !== -1) {
        str = str.replace('{{', '').replace('}}', '');
        //console.log('stringToJs', `return ${str};`);
        let fn = new Function('task', `return ${str};`);
        let result = fn(task);
        //console.log('stringToJs result', result);
        return result;
      } else {
        return str;
      }
    }

    renderField(props){
      const { id, type, label, placeholder, options, schema, rules, value } = props.field;
      //console.log(props)

      switch (type) {
        case 'header':
          return (
            <h2>{label}</h2>
          );
          break;
        case 'text':
          return (
            <Input
              placeholder={placeholder ? placeholder : 'Introducir texto'}
            />
          );
          break;
        case 'int':
          return (
            <InputNumber
              placeholder={placeholder ? placeholder : 'Introducir númerro'}
              style={{width: '100%'}}
            />
          );
          break;
        case 'number':
          return (
            <InputNumber
              placeholder={placeholder ? placeholder : 'Introducir número'}
              style={{width: '100%'}}
            />
          );
          break;
        case 'select':
          return (
            <Select
              showSearch
              placeholder={placeholder ? placeholder : 'Seleccionar opción'}
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {Object.keys(options).map((option, i) => (
                <Option key={option} value={option}>{options[option]}</Option>
              ))}
            </Select>
          );
          break;
        case 'date':
          return (
            <DatePicker
              placeholder={placeholder ? placeholder : 'Seleccionar fecha'}
              format={'DD/MM/YYYY'}
              style={{width: '100%'}}
            />
          );
          break;
        default:
          return null;
      }

    }

    render() {
      //console.log('SchemaField', this.props)
      const { visible, onCancel, onCreate, form, field } = this.props;
      const { getFieldDecorator, getFieldValue } = this.props.form;
      const { schema } = field;
      const uploadButton = (
        <div>
          <Icon type={this.state.loading ? 'loading' : 'plus'} />
          <div className="ant-upload-text">Upload</div>
        </div>
      );
      return (
        <Modal
          visible={visible}
          title={"Añadir un nuevo defecto"}
          okText={"Crear"}
          cancelText={"Cerrar"}
          onCancel={onCancel}
          onOk={onCreate}
        >
          <Form layout="vertical">
            {schema.map(field => {
              let visible = this.visibility(field);
              if (!visible) return null;
              //console.log(field);
              let fieldProps = {};
              let rules = field.rules ? field.rules : [];
              if(field.required) rules = [{ required: field.required, message: 'El campo es obligatorio!'}].concat(rules);
              if([undefined, null, ''].indexOf(field.min) === -1) {
                let minVal = this.rewriteString(field.min);
                let maxVal = this.rewriteString(field.max);
                rules = [{ type: 'number', min: minVal, max: maxVal, message: `El valor debe ser entre ${minVal} y ${maxVal}!`}].concat(rules);
              }
              fieldProps.rules = rules;

              let options = field.options ? stringToJs(field.options) : null;

              let description = null;
              if([undefined, null, ''].indexOf(field.description) === -1) {
                description = this.rewriteString(field.description);
              }
              //console.log(field.id, rules);
              // Set initial value
              let initialValue = null;
              if(field.initialValue) {
                initialValue = stringToJs(field.initialValue);
              }
              fieldProps.initialValue = initialValue;
              fieldProps.placeholder = field.placeholder;
              if(field.type === 'signature') {
                const imageUrl = getFieldValue(field.id);
                return (
                  <Form.Item key={field.id} style={{marginBottom: 0}}>
                    <h3>{field.type !== 'header' ? field.label : null}</h3>
                    {getFieldDecorator(field.id, fieldProps)(
                      <Upload.Dragger
                        name="files"
                        beforeUpload={beforeUpload}
                        showUploadList={false}
                        action={async (file) => {
                          const base64 = await toBase64(file);
                          file.url = base64
                          return false;
                        }}
                      >
                        {imageUrl ? <img src={imageUrl.file.url} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                      </Upload.Dragger>,
                    )}
                    {description ? <div><small>{description}</small></div> : null}
                    <Divider/>
                  </Form.Item>
                );
              }
              return (
                <Form.Item key={field.id} style={{marginBottom: 0}}>
                  <h3>{field.type !== 'header' ? field.label : null}</h3>
                  {getFieldDecorator(field.id, fieldProps)(
                    this.renderField({field: {...field, options, description}}),
                  )}
                  {description ? <div><small>{description}</small></div> : null}
                  <Divider/>
                </Form.Item>
              );
            })}
          </Form>
        </Modal>
      );
    }
  },
);

export default class SchemaField extends React.Component {
  state = {
    visible: false,
  };

  showModal = () => {
    this.setState({ visible: true });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  handleCreate = () => {
    //console.log('handleCreate', this.props)
    const { field, submitted } = this.props;
    const { form } = this.formRef.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      let parsedFields = {};
      Object.keys(values).forEach(key => {
        let item = find(field.schema, { id: key })
        if(item) {
          parsedFields[key] = values[key];
          if(item.type === 'signature') {
            parsedFields[key] = values[key] ? values[key].file.url : null;
          }
          if(item.type === 'date') {
            parsedFields[key] = values[key] ? values[key]._d : null;
          }
        }
      });
      //console.log('Received values of form: ', parsedFields);
      form.resetFields();
      if(submitted) {
        submitted(parsedFields)
      }
      this.setState({ visible: false });
    });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  render() {
    const { field, task } = this.props;
    let disabled = false;
    if(this.props.value && field.max && this.props.value.length >= field.max) {
      disabled = true;
    }
    return (
      <div>
        <Button type="default" icon="plus" onClick={this.showModal} disabled={disabled}>
          Añadir
        </Button>
        <SchemaFieldForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.visible}
          onCancel={this.handleCancel}
          onCreate={this.handleCreate}
          field={field}
          task={task}
        />
      </div>
    );
  }
}
