import React, { Component } from 'react';
import { PageHeader, Layout } from 'antd';

import { GlobalHeader } from '../components/GlobalHeader/GlobalHeader';
import { GlobalFooter } from '../components/GlobalFooter/GlobalFooter';

export class CleanLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    //console.log(this.props)
    return (
      <Layout className="layout">
        <GlobalHeader {...this.props} />
        <PageHeader
          style={{
            backgroundColor: '#FFFFFF',
            border: '1px solid rgb(235, 237, 240)',
          }}
          onBack={() => this.props.history.goBack()}
          title={this.props.title}
          subTitle={this.props.subtitle}
          extra={this.props.extra}
        />
        {this.props.children}
        <GlobalFooter {...this.props} />
      </Layout>
    )
  }
}
