import React, { Component } from 'react';
import { Layout, Row, Col, Divider, Button, Icon, Avatar, Modal, Spin, Input, message } from 'antd';
import Parse from 'parse';
import { CleanLayout } from '../../layout/CleanLayout';

const { Content } = Layout;

export class AppsPage extends Component {
  state = {
  };

  componentDidMount() {
  }

  copyToClipboard(str) {
    /* Get the text field */
    let copyText = document.createElement('input');
    copyText.value = str;

    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /*For mobile devices*/

    /* Copy the text inside the text field */
    document.execCommand("copy");

    /* Alert the copied text */
    message.success("Copiado al portapapeles");
  }

  async iOSLinkGenerator() {
    let secondsToGo = 5;

    const currentUser = Parse.User.current();
    const User = Parse.Object.extend("_User");
    const query = new Parse.Query(User);
    const user = await query.get(currentUser.id);
    if(user) {
      let code = user.get('iosCode');
      if(code) {
        const url = 'https://buy.itunes.apple.com/WebObjects/MZFinance.woa/wa/freeProductCodeWizard?code=' + code;
        const successModal = Modal.success({
          title: 'Código de descarga para iOS',
          okText: 'Cerrar',
          content: (
            <div>
              <p>Útiliza el siguiente código o enlace para descargar la app:</p>
              <h2><b style={{backgroundColor: '#f0f0f0', color: '#555555', border: '#c8c8c8 solid 1px', borderRadius: '4px', padding: '5px 10px'}}>{code}</b> <small>(Solo un uso)</small></h2>
              <Divider />
              <Input.Search
                value={url}
                enterButton={<Icon type="copy" />}
                size="large"
                onSearch={value => this.copyToClipboard(url)}
              />
              <Divider />
              <Button onClick={() => window.open(url)} size="large" block>
                Abrir enlace en iTunes
                <Icon type="link" />
              </Button>
            </div>
          ),
        });
      } else  {
         const errModal = Modal.error({
           title: 'Error generando el código',
           content: `Inténtalo de nuevo.`,
         });
      }
    }
  }

  render() {
    const { I18N, user, isAdmin, isVendor } = this.props;
    const { currentPage, loading, techs } = this.state;
    console.log('Home render', this);

    return (
      <CleanLayout {...this.props} title={I18N.Apps}>
        <div style={{backgroundColor: '#FFFFFF', padding: '100px 0'}}>
          <Row>
            <Col span={6}></Col>
            <Col span={12}>
              <Row>
                <Col span={12} style={{textAlign: 'center'}}>
                  <Avatar shape="square" size={180} icon="android" style={{ color: '#1bbc45', backgroundColor: '#cffdd6' }} />
                  <h2 style={{margin: 5}}>App para Android<br/><small>Play Store</small></h2>
                  <Button onClick={() => window.open('https://play.google.com/store/apps/details?id=com.primagas.techs')}>Abrir en Play Store</Button>
                </Col>
                <Col span={12} style={{textAlign: 'center'}}>
                  <Avatar shape="square" size={180} icon="apple" style={{ color: '#333333', backgroundColor: '#cccccc' }} />
                  <h2 style={{margin: 5}}>App para iOS<br/><small>iTunes</small></h2>
                  <Button onClick={() => this.iOSLinkGenerator()}>Generar código</Button>
                </Col>
                <Col span={24} style={{textAlign: 'center'}}>
                  <p></p>
                </Col>
              </Row>
            </Col>
            <Col span={6}></Col>
          </Row>
        </div>
      </CleanLayout>
    )
  }
}
