import moment from 'moment';
import Parse from 'parse';
import { getStatus } from '../../utils/utils';

export const taskExport = ({ start, end, user, isAdmin }) => {
    return new Promise(async resolve => {

        let out = [];

        const Task = Parse.Object.extend('Task');
        const taskQuery = new Parse.Query(Task);
        if (!isAdmin) {
            let Primagas_Id = user.get('Primagas_Id');
            taskQuery.equalTo('Resource_first_line', `R${Primagas_Id}`);
        }
        if (start && end) {
            let startDate = moment(start.format('YYYY-MM-DD') + ' 00:00:01')._d;
            let endDate = moment(end.format('YYYY-MM-DD') + ' 23:59:59')._d;
            taskQuery.greaterThanOrEqualTo('Planned_Service_Date', startDate);
            taskQuery.lessThanOrEqualTo('Planned_Service_Date', endDate);
        }
        taskQuery.ascending('Planned_Service_Date');
        taskQuery.limit(2000);

        let tasks = await taskQuery.find().then(results => {
            return results;
        }).catch(err => {
            console.log('RESULTS', err);
            return [];
        });

        for (let i = 0; i < tasks.length; i++) {

            let task = tasks[i].toJSON();

            let item = task;

            item.id = task.objectId;

            item.createdAt = moment(moment(task.createdAt).format('YYYY-MM-DD 00:00:01'))._d;
            item.updatedAt = moment(moment(task.updatedAt).format('YYYY-MM-DD 00:00:01'))._d;
            item.Planned_Service_Date = moment(moment(task.Planned_Service_Date.iso).format('YYYY-MM-DD 00:00:01'))._d;
            if(item.Status) {
                item.Status = getStatus(item.Status);
            }

            // console.log('TASK', item);
            out.push(item);
        }
        console.log('TASKS', out);
        resolve(out);

    })
};
