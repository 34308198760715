import { find } from 'lodash';
import moment from 'moment';
/*
  Status
  Status: 'Status|xsd:string|Pending,In_Process,Finished,On_Hold',
*/

export const Status_List = [
  {
    id: 'Pending',
    name: 'Pendiente',
    color: 'blue'
  },
  {
    id: 'In_Process',
    name: 'En Proceso',
    color: 'green'
  },
  {
    id: 'Finished',
    name: 'Finalizado',
    color: 'blue'
  },
  {
    id: 'On_Hold',
    name: 'En Espera',
    color: 'orange'
  }
];

export const TasksInfoFields = [
  {
    id: 'No',
    text: 'Document Number',
    label: 'Nº de Pedido',
    __type: 'string'
  },
  {
    id: 'Customer_No',
    text: 'Customer Number',
    label: 'Nº de Cliente',
    __type: 'string'
  },
  {
    id: 'Installation_No',
    text: 'Installation number ',
    label: 'Nº Instalación',
    __type: 'string'
  },
  {
    id: 'Name',
    text: 'Name',
    label: 'Nombre de cliente',
    __type: 'string'
  },
  {
    id: 'VAT_Registration_No',
    text: 'CIF/NIF/NIE',
    label: 'Identificador fiscal',
    __type: 'string'
  },
  {
    id: 'Address',
    text: 'Address (Dirección de suministro 1)',
    label: 'Dirección',
    __type: 'string'
  },
  {
    id: 'Address_2',
    text: 'Ship-to Address 2',
    label: 'Dirección 2',
    __type: 'string'
  },
  {
    id: 'Address_Type',
    text: 'Ship-to Address Type',
    label: 'Tipo de dirección',
    __type: 'string'
  },
  {
    id: 'Post_Code',
    text: 'Postal Code (de suministro)',
    label: 'CP',
    __type: 'string'
  },
  {
    id: 'City',
    text: 'City (de suministro)',
    label: 'Ciudad',
    __type: 'string'
  },
  {
    id: 'Country_Region_Code',
    text: 'Country_Region_Code (de suministro)',
    label: 'País',
    __type: 'string'
  },
  {
    id: 'Phone_No',
    text: 'Telefonos',
    label: 'Teléfonos',
    __type: 'array',
    children: ['Phone_No', 'Phone_No_2', 'Phone_No_3']
  },
  {
    id: 'Serial_No_Deposito',
    text: 'Serial nº (depósito)',
    label: 'Nº Depósito',
    __type: 'string'
  },
  {
    id: 'Serial_No_Contador',
    text: 'Serial nº (contador)',
    label: 'Nº Contador',
    __type: 'string'
  },
  {
    id: 'Resource_first_line',
    text: 'Resource',
    label: 'Nº de Instalador',
    __type: 'string'
  },
  {
    id: 'Service_Order_Type',
    text: 'Service Type Code',
    label: 'Tipo de Servicio',
    __type: 'string'
  },
  {
    id: 'Description',
    text: 'Service Line to description',
    label: 'Descripción',
    __type: 'string'
  },
  {
    id: 'Content_of_Tank',
    text: 'Content (capacidad del depósito)',
    label: 'Capacidad de Depósito',
    __type: 'string'
  },
  {
    id: 'Position_Tank',
    text: 'Position tank',
    label: 'Posición del Tanque',
    __type: 'string'
  },
  {
    id: 'Automatic_Delivery',
    text: 'Automatic delevery (este es un campo de si o no, queremos que se indique que si está marcado quiere decir que tiene la instalación lleva telemdida)',
    label: 'Entrega Automática',
    __type: 'string'
  },
  {
    id: 'Planned_Service_Date',
    text: 'Planned service Date',
    label: 'Fecha planificada de Servicio',
    __type: 'string'
  },
  {
    id: 'New_Service_Date',
    text: 'New service Date',
    label: 'Fecha otorgada de Servicio',
    __type: 'string'
  },
  {
    id: 'Resource_Def_Hours',
    text: 'Resource Def hours Unit',
    label: 'Resource Def hours Unit',
    __type: 'string'
  },
  {
    id: 'Resource_Ext_Cost_Price',
    text: 'Resource Ext cost price (precio del pedido)',
    label: 'Precio de coste extra',
    __type: 'string'
  },
  {
    id: 'Unit_Cost_LCY',
    text: 'Unit_Cost_LCY',
    label: 'Precio del pedido',
    __type: 'string'
  }
];

export const TaskGlobalTypes = {
  Maintenance: [
    'INSP IN 4A',
    'INSP IN 5A',
    'MTO EXT 1A',
    'MTO EXT 2A',
    'RET DEP 10',
    'RET DEP 15',
    'REV EXT 4A',
    'REV EXT 5A',
    'REV INT 4A',
    'REV INT 5A',
    'RET CONTAD',
    'ALTA CONT',
    'RECOL-CON',
    'S24H',
    'REPAR',
    'MTO TEL',
    'MTO NIVEL',
    'COL TEL',
    'COL NIVEL',
    'MTO VAPO',
    'MTO RED',
    'RET VAPO15',
    'RET VAPO10',
    'MTO KIT',
    'BAJA INERT',
    'MTO LIRA',
    'MTO EXTINT',
    'MTO GNL 1A',
    'REV INT 2A',
    'BAJA DEP',
    'MT EXT GNL',
    'REV EXT 2A',
    'REV OCA',
    'MTO GNL 5A',
    'S24H GNL',
    'MTO KIT'
  ],
  Installation: [
    'BOCA DE CARGA',
    'CG EXT',
    'CG INT',
    'CONT',
    'DEP',
    'ECD',
    'EQUIPOS',
    'EXTINT',
    'GI',
    'LI EXT',
    'LI INT',
    'OC DEP',
    'OC VALL',
    'OTRO EXT',
    'OTRO INT',
    'PI EXT',
    'PR EXT',
    'PR INT'
  ]
};

export const getGlobalType = (type) => {
  if(TaskGlobalTypes.Maintenance.indexOf(type) !== -1) {
    return 'Maintenance';
  }
  if(TaskGlobalTypes.Installation.indexOf(type) !== -1) {
    return 'Installation';
  }
  return 'Maintenance';
};

export const getGlobalTypeName = (type) => {
  if(TaskGlobalTypes.Maintenance.indexOf(type) !== -1) {
    return 'Mantenimineto';
  }
  if(TaskGlobalTypes.Installation.indexOf(type) !== -1) {
    return 'ID';
  }
  return 'Mantenimineto';
};

export const getStatus = (id) => {
  return find(Status_List, { id });
};

export const getField = (id) => {
  return find(TasksInfoFields, { id });
};

export const invalidServices = [
'MTO GNL',
'BAJA',
'BAJA',
'MTO INT 2A',
'RECUP DEP',
'ALTA CONT',
'RET DEP 12',
'MTO VAPO',
'INSTALACIO',
'BAJA',
'BAJA',
'BAJA',
'CALDERA',
'VAP'
];

export const serviceTypeApp = (type) => {
  if(invalidServices.indexOf(type) !== -1) {
    return 0;
  }
  return 1;
};

export const TaskFieldsByType = {
  Maintenance: ['No', 'Installation_No', 'Customer_No', 'Name', 'Address', 'Address_2', 'Serial_No_Deposito', 'Serial_No_Contador', 'Post_Code', 'City', 'Country_Region_Code', 'Phone_No', 'Resource_first_line', 'Description', 'Content_of_Tank', 'Position_Tank', 'Planned_Service_Date', 'Unit_Cost_LCY'],
  Installation: ['No', 'Installation_No', 'Customer_No', 'Name', 'Address', 'Address_2', 'Address_Type', 'Post_Code', 'City', 'Country_Region_Code', 'Phone_No', 'Resource_first_line', 'Service_Order_Type', 'Description', 'Content_of_Tank', 'Automatic_Delivery', 'Position_Tank', 'Planned_Service_Date', 'Resource_Def_Hours', 'Resource_Ext_Cost_Price', 'Unit_Cost_LCY']
};

export const getFieldsGlobalType = (type) => {
  if(TaskGlobalTypes.Maintenance.indexOf(type) !== -1) {
    return TaskFieldsByType.Maintenance;
  }
  if(TaskGlobalTypes.Installation.indexOf(type) !== -1) {
    return TaskFieldsByType.Installation;
  }
    return TaskFieldsByType.Maintenance;
};

export const defectsList = {
  "E29.2":"FUGA EN MANGUERA",
  "PLTM":"Placing Telemetry",
  "1.0":"BAJA",
  "1.1":"FUGA DE GAS >5l/h",
  "1.2":"FUGA DE GAS <5l/h",
  "1.3":"MATERILES DE INSTALACIÓN NO AUTORIZADOS",
  "1.4":"LLAVE NO ACCESIBLE O EN MALAS CONDICIONES",
  "1.5":"TUBERÍRA EN CONTACTO CON CABLES ELÉCTRICOS,...",
  "1.6":"TUBERÍA SIN ENVAINAR ATRAVESANDO CAMARAS...",
  "1.7":"FUNC DEFC VALVULA SEGURIDAD/REGULADOR",
  "1.8":"TOMA APARATO SIN OBTURAR",
  "1.9":"ALMACENAMIENTO BOTELLA NO REGLAMENTARIO",
  "2.1":"TUBERÍA FLEXIBLE VISIBLEMENTE DAÑADA",
  "2.2":"TUBERÍA FLEXIBLE  INADECUADA",
  "2.3":"FLEXIBLE CADUCADO",
  "2.4":"LONGITUD FLEXIBLE INADECUADA",
  "2.5":"BOQUILLAS, ABRAZADERA O CONEXIÓN INADECUADAS",
  "2.6":"FLEXIBLE SOMETIDO A CALOR EXCESIVO",
  "2.7":"ELECTROVÁLVULA DEFECTUOSA",
  "3.1":"APARATOS EN DOMITORIO,BAÑO, SOTANO...",
  "3.10":"OTROS",
  "3.11":"FUGA EN CALDERA",
  "3.12":"REGULADOR OBTURADO",
  "3.13":"FUGA EN REGULADOR DE APARATO",
  "3.14":"FUGA COCINA",
  "3.15":"FUGA",
  "3.16":"MAL FUNCIONAMIENTO REGULADOR",
  "3.17":"FALTA REGULADOR POR MÍNIMA",
  "3.2":"COMBUSTIÓN PELIGROSA >1000PPM",
  "3.3":"NO EXISTE O NO FUNCIONA LOS DISP. SEGURIDAD",
  "3.4":"MALA COMBUSTIÓN >400 E <1000PPM",
  "3.5":"FALTA O NO FUNCIONA ENCLAVAMIENTO CAMPANA",
  "3.6":"INERTIZADO",
  "3.7":"FALTA MANTENIMIENTO CALDERA",
  "3.8":"AVERIA CALDERA",
  "3.9":"AVERÍA COCINA",
  "4.1":"REVOCO DE GASES AL LOCAL CONC.ENTRE 15 Y 50 ppm",
  "4.2":"FALTA CONDUCTO DE EVACUACIÓN",
  "4.3":"CAMPANA CONECTADA CONDUCTO EVACUACIÓN APARATO TIRO",
  "4.4":"CONDUCTO NO ESTANCO",
  "4.5":"APARATO TIRO FORZADO CONECTADO A CONDUCTO NO ADECU",
  "5.1":"FALTA ORIFICIO ENTRADA AIRE (EXC APARATOS ESTANCOS",
  "5.10":"DETECTOR FUGA INTERIOR SALTA O NO FUNC",
  "5.2":"ORIFICIO ENTRADA AIRE INS, OBSTRUIDO O ALTURA INAD",
  "5.3":"VENTILlACIÓN INSUFICIENTE",
  "5.4":"SALIDAD GASES INS, OBSTRUIDA O ALTURA INADECUADA",
  "5.5":"LOCAL VOLUMEN INSUFICIENTE",
  "5.6":"AUSENCIA VENTILACIÓN RÁPIDA SIENDO NECESARIA",
  "5.7":"DISTANCIA INADECUADO ENTRE COCINA Y CALDERA",
  "5.8":"FALLA SISTEMA DETECCIÓN Y CORTE GAS",
  "5.9":"ALAMACENAMIENTO BOTELLA NO ADECUADO",
  "6.0":"TUBERIA SIN ENVAINAR",
  "7.0":"OTROS",
  "7.1":"FALSA AVERÍA",
  "B10":"CALENTADOR ALTURA INCORRECTA",
  "B5":"APARATO ACOPLADO A TUBERÍA INCORRECTA",
  "E1.1":"FALTA PICA TIERRA",
  "E1.2":"PICA >80 OHMS",
  "E1.3":"PICA DESCONECTADA",
  "E10.1":"FALTAN EXTINTORES",
  "E10.10":"FALSO AVISO",
  "E10.2":"FALTA MANTENIMIENTO EXTINTORES",
  "E10.3":"EXTINTORES EN MAL ESTADO",
  "E10.4":"FALTA ARMARIO PARA EXTINTORES",
  "E10.5":"EXTINTORES NO VISIBLES",
  "E11.1":"FALTAN CARTELES O ESTÁN EN MAL ESTADO",
  "E12.1":"FALTAN GUANTES",
  "E13.1":"FALTA CERRAMIENTO",
  "E13.2":"ALTURA VALLA INSUFICIENTE",
  "E13.51":"PUERTA DESCOLGADA / BISAGRA ROTA",
  "E13.52":"FALTA PUERTA",
  "E13.6":"FALTA CERRADURA O CANDADO",
  "E13.7":"CERRADURA O CANDADO ROTO",
  "E13.8":"DISTANCIA SEGURIDAD INSUFICIENTES",
  "E13.9":"MATERIAL INADECUADO",
  "E14.1":"SALIDA DEPOSITO EN COBRE",
  "E14.10":"TUBERÍA ROTA",
  "E14.11":"VAINAS DE MATERIAL NO AUTORIZADO",
  "E14.13":"FALTA PINTAR TUBERIA",
  "E14.15":"FUGA EN RED DE CANALIZADO",
  "E14.2":"FALTA PROTECCIÓN TUBERÍA",
  "E14.3":"FALTA SEÑALIZAR TUBERÍA",
  "E14.4":"FALTA SUJETAR TUBERÍA",
  "E14.5":"TUBERÍA EN MAL ESTADO",
  "E14.6":"FALTA JUNTA DIELÉCTRICA",
  "E14.7":"FALTA PASAMUROS",
  "E14.8":"TUBERÍA ENTERRADA A <50cm",
  "E14.9":"TUBERÍA DESENTERRADA",
  "E15.10":"LLAVE CERRADA",
  "E15.11":"FALTA CAJA PROTECCIÓN LLAVE ACOMETIDA",
  "E15.12":"CAJA PROTECCIÓN ROTA O DEFECTUOSA",
  "E15.13":"LLAVE NO ACCESIBLE",
  "E15.2":"LLAVE CORTE DEPÓSITO DEFECTUOSA",
  "E15.20":"FALTA LLAVE CORTE DESPUES DE REGULACIÓN",
  "E15.3":"FALTA LLAVE CORTE DEPÓSITO",
  "E15.4":"FUGA EN LLAVE ACOMETIDA",
  "E15.5":"LLAVE ACOMETIDA DEFECTUOSA",
  "E15.6":"FALTA LLAVE ACOMETIDA",
  "E15.7":"FUGA LLAVE EDIFICIO",
  "E15.8":"LLAVE EDIFICIO DEFECTUOSA",
  "E15.9":"FALTA LLAVE EDIFICIO",
  "E16.1":"FUGA REGULADOR",
  "E16.2":"REGULADOR MAL DIMENSIONADO",
  "E16.3":"FUNCIONAMIENTO DEFECTUOSO",
  "E16.4":"BLOQUEADO POR CONGELACIÓN",
  "E16.5":"REGULADOR OBTURADO",
  "E16.6":"REGULADOR BLOQUEADO POR FALTA DE USO",
  "E16.7":"Varios",
  "E17.2":"LIMITDOR MAL DIMENSIONADO",
  "E17.3":"FUNCIONAMIENTO DEFECTUOSO",
  "E17.4":"FALTA LIMITADOR",
  "E18.2":"MULTIALVULA DEFECTUOSA",
  "E18.3":"VALVULA EXCESO FLUJO BLOQUEADA",
  "E18.4":"FALTA TAPÓN",
  "E18.5":"FALTA VALVULA EXCESO FLUJO",
  "E19.2":"FALTA JUNTA",
  "E19.3":"MATERIAL SELLADO INADECUADO",
  "E19.4":"CONEXIONES CON MATERIAL INADECUADO",
  "E19.5":"SALIDA DEPÓSITO EN COBRE",
  "E19.6":"FUGA EN CHECKLOCK",
  "E19.7":"CHECKLOCK EN MAL ESTADO",
  "E19.8":"FUGA EN ASCENDENTE",
  "E2.1":"FALTA TUBO BUZO",
  "E2.2":"DIAMETRO TUBO INSUFICIENTE",
  "E2.3":"FALTA TAPA TUBO BUZO",
  "E2.4":"TUBO BUZO OBSTRUIDO",
  "E2.5":"TUBO BUZO NO VISIBLE",
  "E20.1":"FUGA EN BOCA DE CARGA",
  "E20.10":"FALTA LLAVE CORTE DESPUÉS BOCA",
  "E20.11":"NECESARIO MODIFICAR BOCA DE CARGA",
  "E20.4":"FALTA KIT BY-PASS EN BOCA DESPLAZADA",
  "E20.5":"NO FUNCIONA KIT BY-PASS",
  "E20.7":"TUBERÍA BOCA DESP SIN SEÑALIZAR",
  "E20.8":"ARMARIO BOCA DE CARGA DEFETUOSO",
  "E20.9":"FUGA BOCA DE CARGA DESPLAZADA",
  "E21.3":"FALTA MANÓMETRO-TOMA PETTERSON",
  "E22.2":"NIVEL INTERNO DEFECTUOSO",
  "E22.4":"FALTA TAPA RELOJ NIVEL",
  "E22.5":"BOCA DE CARGA DEPÓSITO DEFECTUOSA",
  "E23.1":"FUNCIONAMIENTO DEFECTUOSO",
  "E23.2":"FUGA EN PUNTO ALTO",
  "E23.3":"PUNTO ALTO OBTURADO",
  "E23.4":"FALTA PUNTO ALTO",
  "E24.1":"FALTA VENTILACIÓN",
  "E24.2":"CERRADURA  NO NORMALIZADA",
  "E24.3":"CERRADURA ROTA O EN MAL ESTADO",
  "E24.4":"CANDADO ROTO O EN MAL ESTADO",
  "E24.5":"ARMARIO CONTADOR ROTO",
  "E24.6":"FALTA PUERTA",
  "E24.7":"PROBLEMAS UBICACIÓN",
  "E25.1":"FUGA ENTRADA CONTADOR",
  "E25.10":"LLAVE EN MAL ESTADO/FUNCIONAMIENTO DEF.",
  "E25.2":"FUGA SALIDA CONTADOR",
  "E25.3":"REGULADOR CONTADOR DEFECTUOSO",
  "E25.4":"REGULADOR CONTADOR BLOQUEADO",
  "E25.5":"FUNCIONAMIENTO DEFECTUOSO",
  "E25.6":"FUGA INTERIOR CONTADOR",
  "E25.7":"LIMITADOR DEFECTUOSO",
  "E25.8":"FUGA REGULADOR",
  "E25.9":"LLAVE CONTADOR CERRADA",
  "E26.1":"INCUMPLE DISTANCIA REF 1",
  "E26.2":"INCUMPLE DISTANCIA REF 2",
  "E26.3":"INCUMPLE DISTANCIA REF 3",
  "E26.4":"INCUMPLE DISTANCIA REF 4",
  "E26.5":"INCUMPLE DISTANCIA REF 5",
  "E26.6":"INCUMPLE DISTANCIA REF 6",
  "E27.0":"TELEMEDIDA NO EMITE",
  "E27.1":"INSTALACIÓN MODIFICADA",
  "E27.10":"NO LLEGA EL GAS",
  "E27.11":"AGUA EN CIRCUITO DE GAS",
  "E27.12":"PROBELMA TURBINA GENERADOR",
  "E27.13":"BAJA",
  "E27.14":"COLOCACIÓN NIVEL A DISTANCIA",
  "E27.15":"COLOCACIÓN TELEMEDIDA",
  "E27.16":"QUEJA CONSUMO",
  "E27.17":"NIVEL A DISTANCIA AVERIADO",
  "E27.2":"FALTA LIBRO MANTENIMIENTO",
  "E27.3":"OTROS",
  "E27.4":"FALTA GAS",
  "E27.5":"PROBLEMA MANGUERA",
  "E27.6":"PROBLEMA SUMINISTRO",
  "E27.7":"SOBRELLENADO",
  "E27.8":"OLOR A GAS",
  "E27.9":"INCENDIO",
  "E28.1":"FUNCIONAMIENTO DEFECTUOSO",
  "E28.2":"FUGA VAPORIZADOR",
  "E28.3":"PROBLEMA CALDERA VAPO",
  "E28.4":"PROBLEMA FUNCIONAMIENTO VAPORIZADOR",
  "E28.5":"FALTA AGUA EN CIRCUITO CALEFACCIÓN",
  "E28.6":"VAPORIZADOR BLOQUEADO",
  "E28.7":"OTROS",
  "E29.3":"FALTA TOMA TIERRA SURTIDOR",
  "E29.4":"FUNCIONAMIENTO DEFECTUOSO BOMBA",
  "E29.5":"FUGA EN SURTIDOR",
  "E29.6":"FUNCIONAMIENTO DEFECTUOSO LLAVE CORTE",
  "E29.7":"FUGA POR JUNTAS",
  "E29.8":"SE CAMBIA JUNTA POR PRECAUCIÓN",
  "E3.1":"PROTECCIÓN CATÓDICA >-0.85 voltios",
  "E3.2":"SIN CONECTAR",
  "E3.3":"ANODOS AL DESCUBIERTO",
  "E3.4":"FALTA PROTECCIÓN CATÓDICA",
  "E35.1":"FUNCIONAMIENTO DEFECTUOSO",
  "E35.2":"FUGA VÁLVULA SEGURIDAD",
  "E35.3":"FALTA TAPA VÁLVULA SEGURIDAD",
  "E36.1":"NO DA SEÑAL POR AVERIA",
  "E36.2":"MAL FUNCIONAMIENTO POR CONTACTO CON AGUA",
  "E36.3":"OTROS(TRABJOS INST. EN RETIMBRADO DEP)",
  "E36.4":"RETIMBRADO CADUCADO",
  "E4.2":"ESCALERA SIN SUJETAR",
  "E5.3":"NECESIDAD DE REPINTAR DEPÓSITO",
  "E5.4":"DISTANCIA CHECK-LOCK DEP AL SUELO <50cm",
  "E5.5":"FALTA PLACA INDUSTRIA",
  "E5.6":"FALTA PEGATINA",
  "E6.1":"MARCA HUMEDAD",
  "E6.3":"FALTAN PILAS EN DETECTOR",
  "E6.4":"HUMEDAD PERMANENTE",
  "E7.10":"ARQETA MUY PROFUNDA > 50 cm",
  "E7.11":"ARQUETA LLENA DE BARRO/ TIERRA",
  "E7.2":"FALTA PRENSAESTOPA EN SALIDA TUBO",
  "E7.3":"AGUA EN ARQUETA",
  "E7.6":"CIERRE CAPOTA/ARQUETA ROTO",
  "E7.7":"FALTA CANDADO",
  "E7.8":"CANDADO ROTO O EN MAL ESTADO",
  "E7.9":"ARQUETA DEFORMADA",
  "E8.1":"ZAPATAS/LOSA DIMENSIONES INCORRECTAS",
  "E8.2":"FALTA ANCLAR DEPÓSITO",
  "E8.3":"TORNILLOS DE ANCLAJE INSUFICIENTES",
  "E8.4":"ZAPATAS O LOSA ROTA/INSEGURA",
  "E8.5":"ANCLAJES DEFECTUOSOS",
  "E9.10":"FUGA PRÓXIMA ACCIDENTE",
  "E9.5":"FALTA PARARRAYOS",
  "E9.6":"CABLES ELECTRICOS",
  "E9.7":"FALTA ARENA EN LA FOSA",
  "E9.8":"SUELO PAVIMENTADO",
  "E9.9":"ZONA INSEGURA",
  "SD":"Instalación Sin Defecto",
  "E13.3":"VALLADO ROTO",
  "E13.4":"CERRADURA NO NORMALIZADA",
  "E13.5":"PUERTA ABRE HACIA DENTRO",
  "E14.14":"TUBERÍA EMPOTRADA",
  "E15.1":"FUGA EN LLAVE CORTE DEPÓSITO",
  "E17.1":"FUGA LIMITADOR",
  "E18.1":"FUGA MULTIVÁLVULA",
  "E19.1":"FUGA JUNTAS",
  "E20.2":"FALTA TAPÓN",
  "E20.3":"IMPOSIBILIDAD CONEXIÓN MANGUERA",
  "E20.6":"FALTA/ROTA CERRADURA ARM BOCA DESPLAZADA",
  "E21.1":"FUGA MANÓMETRO",
  "E21.2":"MANÓMETRO DEFECTUOSO",
  "E22.1":"FUGA NIVEL",
  "E22.3":"RELOJ NIVEL DEFECTUOSO",
  "E29.1":"FUGA EN BOQUEREL",
  "E4.1":"FALTA ESCALERA",
  "E5.1":"DEPÓSITO SUCIO",
  "E5.2":"PUNTOS DE OXIDACIÓN",
  "E6.2":"DETECTOR HUMEDAD NO FUNCIONA",
  "E7.1":"NIVEL ARQUETA RESPECTO AL SUELO < 20cm",
  "E7.4":"TAPA CAPOTA/ARQUETA ROTA",
  "E7.5":"FALTA CAPOTA",
  "E9.1":"HAY VEGETACION",
  "E9.2":"MATERIAL COMBUSTIBLE",
  "E9.3":"MATERIALES AJENOS",
  "E9.4":"PROBLEMAS DE ACCESO"
};

export const resolutionCodes = {
  "1"	: "Sin Defecto",
  "3" : "Con Defecto Instalador",
  "4" : "Con Defecto Cliente",
  "6" : "Con Defecto Instalador / Cliente",
  "7" : "Ruido por vibración",
  "8" : "Depósito vacío",
  "9" : "Regulador Aparato Defectuoso",
  "10" : "Microfuga Instalación Receptora",
  "11" : "Obturación por Defecto Instalación Receptora",
  "12" : "Obturación por Defecto Instalación Exterior",
  "13" : "Microfuga Detectada con Agua Jabonosa",
  "14" : "Fuga con Leve Sonido Escape",
  "15" : "Fuga con Gran Sonido Escape",
  "16" : "Fuga Gas Líquido",
  "17" : "Sobrellenado",
  "18" : "Congelación",
  "19" : "Depósito nuevo con nitrógeno no purgado completamente",
  "20" : "Otros Instalación Nueva",
  "21" : "Vaporizador averiado",
  "22" : "Fuga por punto alto abierto",
  "23" : "Manómetro depósito defectuoso",
  "24" : "Caldera Cliente Defectuosa",
  "25" : "Regulador depósito defectuoso",
  "26" : "Regulador antes de contador defectuoso",
  "27" : "Boquerel defectuoso",
  "28" : "No hay contador",
  "29" : "Llave contadores cerrada (Resolution code provisional)",
  "30" : "Depósito desplazado",
  "31" : "Otros"
};

export const stringToJs = (str, ctx = {}) => {
  //console.log('stringToJs ctx', { defectsList, resolutionCodes, moment })
  if(typeof str === 'object') return str;
  if(typeof str === 'number') return str;
  if(typeof str === 'string' && str.indexOf('{{') !== -1 && str.indexOf('}}') !== -1) {
    str = str.replace('{{', '').replace('}}', '');
    str = str.trim();
    //console.log('stringToJs', `return ${str};`);
    let fn = new Function('defectsList', 'resolutionCodes', 'moment', 'ctx', `return ${str};`);
    let result = fn(defectsList, resolutionCodes, moment, ctx);
    //console.log('stringToJs result', result);
    return result;
  } else {
    return str;
  }
};
