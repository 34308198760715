import React, { Component } from "react";
import { Row, Col, Form, Button, Select, Input, InputNumber, message } from "antd";
import { CleanLayout } from "../../layout/CleanLayout";
import Parse from "parse";

const MaterialRequestForm = Form.create({ name: "material_request_form" }) (
  class extends React.Component {    
    state = {
      selectedCategory: null
    };
  
    validatePostalCode = (_, value, callback) => {
      if(value === undefined)  return callback()
      if (!isNaN(value) && value.length === 5) return callback()
      callback("Código postal inválido!")
    };

    render () {
      const { dataCategory, dataArticle, provinces, createMaterialRequest, form } = this.props;
      const { getFieldDecorator, setFieldsValue } = form;
      const { selectedCategory } = this.state
  
      return (
        <>
          <Form
            layout="inline" 
            style={{marginTop: "2rem"}} 
          >
            <Form.Item label="Categoría">
              {getFieldDecorator("category", {
                rules: [{ required: true, message: "Por favor Seleccione una categoría!" }],
              })(
                <Select
                  placeholder="Seleccione una categoría"
                  style={{width: "14rem"}}
                  autoComplete="nope"
                  optionFilterProp="children"
                  showSearch
                  onChange={(value) => {
                    setFieldsValue( { article: undefined })
                    this.setState({
                      selectedCategory: value,
                    })
                  }}
                >
                  {dataCategory.map(category => (
                    <Select.Option  key={category.id} value={category.id}>{category.name}</Select.Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
            <Form.Item label="Artículo">
              {getFieldDecorator("article", {
                rules: [{ required: true, message: "Por favor seleccione un artículo!" }],
              })(
                <Select
                  placeholder="Seleccione un artículo"
                  style={{width: "14rem"}}
                  autoComplete="nope"
                  optionFilterProp="children"
                  showSearch
                >
                  {dataArticle.filter(({ idCategory }) => idCategory === selectedCategory).map( article => (
                    <Select.Option  key={article.id} value={article.id}>{article.name}</Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item label="Cantidad">
              {getFieldDecorator("quantity", {
                rules: [{ required: true, message: "Por favor seleccione la cantidad!" }],
              })(
                <InputNumber min={1} max={99} placeholder="Seleccione la cantidad"/>
              )}
            </Form.Item>
            <h3 style={{margin: "4rem 0 0 1.4rem"}}>Dirección de entrega</h3>
            <p style={{marginBottom:"2rem", marginLeft: "1.4rem"}}> Solo rellenar si la dirección de entrega es diferente a la que tenemos en la ficha de proveedor.</p>
            <Form.Item label="Dirección">
              {getFieldDecorator("address")(
                <Input
                  maxLength={50} 
                  placeholder="Escribe la dirección" 
                  autoComplete="nope" 
                  style={{width: "50rem"}}
                />
              )}
            </Form.Item>
            <Form.Item label="Población">
              {getFieldDecorator("city")(
                <Input
                maxLength={50} 
                placeholder="Escribe la población" 
                autoComplete="nope" 
                style={{width: "14rem"}}
                />
              )}
            </Form.Item>
            <Form.Item label="Provincía">
              {getFieldDecorator("province")(
                <Select
                  placeholder="Seleccione una provincía"
                  style={{width: "14rem"}}
                  autoComplete="nope"
                  optionFilterProp="children"
                  showSearch
                  onChange={(value) => {
                    this.setState({
                      selectedProvinces: value
                    })
                  }}
                >
                  { provinces.map(province => (
                    <Select.Option key={province.id} value={province.name}>{province.name}</Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item 
              label="Código postal" 
            >
              {getFieldDecorator("postalCode", {
                rules: [{ validator: this.validatePostalCode }],
              })(
                <Input
                  maxLength={5} 
                  placeholder=" Escribe el código postal" 
                  autoComplete="nope" 
                  style={{width: "5.5rem"}}
                />
              )}
            </Form.Item>
          </Form>
          <Button 
            htmlType="submit" 
            type="primary" 
            style={{marginTop: "2rem", marginLeft: "1.3rem"}}
            onClick={createMaterialRequest}
          >
            Nueva solicitud
          </Button>
        </>
      )
    }
  }
)

export class MaterialRequest extends Component {
  _isMounted = false;

  state = { 
    loading: false,
    dataCategory:[],
    dataArticle: [],
    selectedProvinces: undefined,
    provinces: [],
  };

  componentDidMount() {
    this._isMounted = true;
    this.fetchCategory();
    this.fetchArticle();
    this.fetchProvinces()
  };

  fetchCategory = async () => {
    this.setState({ loading: true });
    const Category = Parse.Object.extend("Category");
    const queryCategory = new Parse.Query(Category);
    const responseCategory = await queryCategory.find();

    if(responseCategory) {
      let data = responseCategory.map( ({ id, attributes })=> {
        return { id, name: attributes.name }
      });
      this.setState({
        loading: false,
        dataCategory: data,
      });
    }
  };

  fetchArticle = async () => {
    this.setState({ loading: true });
    const Article = Parse.Object.extend("Article");
    const queryArticle = new Parse.Query(Article);
    const responseArticle = await queryArticle.find();
   
    if(responseArticle) {
      let data = responseArticle.map(({id, attributes }) => {
        return {
         id,
         name: attributes.name,
         idCategory: attributes.category.id
        }
      });
      this.setState({
        loading: false,
        dataArticle: data,
      });
    }
  };

  fetchProvinces= async () => {
    this.setState({ loading: true });
    const Province = Parse.Object.extend("Province");
    const query = new Parse.Query(Province);
    const response = await query.find();
   
    if(response) {
      let data = response.map(({id, attributes }) => {
        return { id, name: attributes.name }
      });

      data.sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      });

      this.setState({
        loading: false,
        provinces: data,
      });
    }
  };

  createMaterialRequest = () => {
    this.setState({ loading: true});
    const { user } = this.props
    const { form } = this.formRef.props;

    form.validateFields((err, values) => { 
      if (err) return 
      
      try { 
        const Material = Parse.Object.extend("Material")
        const material = new Material()
        material.set("address", values.address)
        material.set("city", values.city)
        material.set("province", values.province)
        material.set("postalCode", values.postalCode)
        material.set("quantity", values.quantity) 
        material.set("article", { __type: "Pointer", className: "Article", objectId: values.article});
        material.set("user", { __type: "Pointer", className: "_User", objectId: user.id});
        material.set("processed", false);
        material.save(); 
        this.emailDeliveryNotification(user);
        message.success("La solicitud de material se ha realizado satisfactoriamente.")
      } catch (err) {
        message.error("Error al realizar la solicitud.")
      } finally {
        this.setState({
          loading: false
        })
      }  
    });

    form.resetFields()
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  componentWillUnmount() {
    this._isMounted = false;
  };

  emailDeliveryNotification = (user) => {
    const { company } = user.attributes;
    Parse.Cloud.run("sendEmail", {
      email: [{"email": "alberto.gabas@primagas.es"}, {"email": "toni.puertas@primagas.es"}],
      templateId:'16',
      cc: [{"email": "vanessa.becerra@primagas.es"}],
      data:{
        company: company || ""
      }
    })
    .then((res) =>  res)
    .catch(error => error);
  };

  render() {
    const { I18N } = this.props;
    const { dataCategory, dataArticle, provinces } = this.state; 

    return (
      <CleanLayout {...this.props} title={I18N.Home}>
        <Row>
          <Col span={24}>
            <div style={{ background: "#fff", margin: 24, padding: 30, minHeight: 480}}>
              <MaterialRequestForm
                wrappedComponentRef={this.saveFormRef}
                createMaterialRequest={this.createMaterialRequest}
                dataCategory={dataCategory} 
                dataArticle={dataArticle} 
                provinces={provinces}
              />
            </div>
          </Col>
        </Row>
      </CleanLayout>
    )
  }
}
