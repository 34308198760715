import React, { useState, useEffect } from 'react';
import Parse from 'parse';
import { Button, Modal, Form, Input, DatePicker, message, Spin, Alert } from 'antd';
import moment from 'moment';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { taskExport } from './exporter';
import { TasksInfoFields, getStatus } from '../../utils/utils';

const TaskExporterForm = ({
    user,
    isAdmin,
    visible,
    onFinish,
    onCancel,
}) => {
    const [loading, setLoading] = useState(false);
    const [start, setStart] = useState(null);
    const [end, setEnd] = useState(null);
    const [tasks, setTasks] = useState([]);

    const closeModal = async () => {
        onCancel()
        setStart(null)
        setEnd(null)
        setTasks([])
    };

    const reStart = async () => {
        setStart(null)
        setEnd(null)
        setTasks([])
    };

    const onChange = async ({fieldId, value}) => {
        console.log({ fieldId, value })
        switch(fieldId) {
            case 'start':
                setStart(value ? moment(value, 'DD/MM/YYYY') : null)
                break;
            case 'end':
                setEnd(value ? moment(value, 'DD/MM/YYYY') : null);
                break;
            default:
                return;
        }
    };

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const taksToCSV = () => {
        let fields = {};
        TasksInfoFields.forEach(field => {
            fields[field.id] = field.label
        });
        fields.Status = 'Estado';
        return tasks.map(task => {
            let item = {};
            Object.keys(fields).forEach(key => {
                if (!task[key]) {
                    item[fields[key]] = '';
                } else {
                    item[fields[key]] = task[key];
                    if (key === 'Status') {
                        item[fields[key]] = task[key].name;
                    }
                }
            });
            return item;
        });
    }

    const exportToCSV = () => {
        let fileName = `Primagas Pedidos Exportados (Fecha ${moment().format('DD-MM-YYYY')})`;
        if(start && end) {
            fileName = `Primagas Pedidos Exportados (Entre ${start.format('DD-MM-YYYY')} - ${end.format('DD-MM-YYYY')}) (Fecha ${moment().format('DD-MM-YYYY')})`;
        }
        console.log(taksToCSV())
        const ws = XLSX.utils.json_to_sheet(taksToCSV());
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
        //closeModal();
    }

    const radioStyle = {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
    };

    return (
        <Modal
            visible={visible}
            title="Exportador de pedidos"
            okText="Buscar pedidos"
            cancelText="Cerrar"
            closable={false}
            onCancel={closeModal}
            onOk={async () => {
                let values = { start, end };
                console.log(values)
                setLoading(true);
                let tasks = await taskExport({ ...values, user, isAdmin  });
                setTasks(tasks);
                setLoading(false);
            }}
        >
            {!loading ? (
                <div>
                    
                    {tasks.length > 0 ? (
                        <div>
                            <Alert
                                message="Listado de pedidos generado"
                                description={'Haz click en el botón \"Descargar archivo\" para proceder con la descarga.'}
                                type="success"
                            />
                            <br />
                            <p><b>{tasks.length}</b> pedidos encontrados.</p>
                            <Button type="default" icon="download" onClick={(e) => exportToCSV()}>Descargar archivo</Button>
                            <Button type="link" onClick={(e) => reStart()}>Comenzar de nuevo</Button>
                        </div>
                    ) : (
                        <div>
                            <Alert
                                message="¿Cómo funciona?"
                                description={'Selecciona las fechas que quieres descargar y haz click en el botón \"Buscar pedidos\" para buscar todos los pedidos. O deja las fechas en blanco para descargar todos.'}
                                type="info"
                            />
                            <br />
                            <Form
                                layout="vertical"
                            >
                                <Form.Item
                                    name="start"
                                    label="Seleccionar día inicial"
                                    rules={[{ required: true, message: 'Selecciona un día' }]}
                                >
                                    <DatePicker onChange={(e, value) => { onChange({ fieldId: 'start', value }) }} format={'DD/MM/YYYY'} />
                                </Form.Item>
                                <Form.Item
                                    name="end"
                                    label="Seleccionar día final"
                                    rules={[{ required: true, message: 'Selecciona un día' }]}
                                >
                                    <DatePicker onChange={(e, value) => { onChange({ fieldId: 'end', value }) }} format={'DD/MM/YYYY'} />
                                </Form.Item>
                            </Form>
                        </div>
                    )}
                </div>
            ) : (
                <Spin tip="Cargando...">
                    <Alert
                        message="Generando listado de tareas"
                        description="Una vez completado el proceso podrás descargar el archivo."
                        type="info"
                    />
                </Spin>
            )}
        </Modal>
    );
};

const TaskExporter = (props) => {
    const [visible, setVisible] = useState(false);
    const onCreate = values => {
        setVisible(false)
    };

    const onCancel = values => {
        setVisible(false)
    };

    return (
        <div>
            <Button icon="file-excel" onClick={() => setVisible(true)}>Exportar</Button>
            <TaskExporterForm
                user={props.user}
                isAdmin={props.isAdmin}
                visible={visible}
                onFinish={onCancel}
                onCancel={onCancel}
            />
        </div>
    );
};

export default TaskExporter;
