import moment from 'moment';
import { defectsList } from '../../utils/utils';

const model = ({ task, worker, fields }) => {
  let items = [];

  const getTaskField = (fieldId) => {
    if(task[fieldId]) {
      return task[fieldId];
    } else {
      return '...';
    }
  };

  const getWorkerField = (fieldId) => {
    if(worker[fieldId]) {
      return worker[fieldId];
    } else {
      return '...';
    }
  };

  const getField = (fieldId) => {
    if(fieldId === 'Remark') {
      if(fields[fieldId]) {
        return fields[fieldId];
      } else {
        return [];
      }
    } else {
      if(fields[fieldId]) {
        return fields[fieldId];
      } else {
        return '...';
      }
    }
  };

  const getDate = (from, fieldId) => {
    if(from[fieldId]) {
      return moment(from[fieldId]).format('DD/MM/YYYY');
    } else {
      return '...';
    }
  };

  let fullAddress = [];
  if(task['Address']) {
    fullAddress.push(getTaskField('Address'))
  }
  if(task['Address_2']) {
    fullAddress.push(getTaskField('Address_2'))
  }
  if(task['Post_Code']) {
    fullAddress.push(getTaskField('Post_Code'))
  }
  if(task['City']) {
    fullAddress.push(getTaskField('City'))
  }

  items.push({
    layout: 'noBorders',
    table: {
      widths: ['*', '*'],
      body: [
        [
          { text: 'Fecha:'.toUpperCase(), bold: true },
          { text: getDate(fields, 'Execution_Date_first_line'), fontSize: 10 }
        ],
      ]
    }
  });
  items.push({ text: '', margin: [0,30,0,0] });
  items.push({ text: 'Datos del cliente:'.toUpperCase(), bold: true });
  items.push({ text: '', margin: [0,10,0,0] });
  items.push({
    layout: 'noBorders',
    table: {
      widths: ['*', '*'],
      body: [
        [
          { text: 'Número de cliente:' },
          { text: getTaskField('Customer_No') }
        ],
        [
          { text: 'Nombre:' },
          { text: getTaskField('Name') }
        ],
        [
          { text: 'Teléfono:' },
          { text: getTaskField('Phone_No') }
        ],
        [
          { text: 'Dirección:' },
          { text: fullAddress.join(', ') }
        ],
      ]
    },
    fontSize: 10
  });
  items.push({ text: '', margin: [0,30,0,0] });
  items.push({ text: 'Datos del instalador:'.toUpperCase(), bold: true });
  items.push({ text: '', margin: [0,10,0,0] });
  items.push({
    layout: 'noBorders',
    table: {
      widths: ['*', '*'],
      body: [
        [
          { text: 'Nombre del técnico:' },
          { text: getField('Tech_Name') }
        ],
        [
          { text: 'Razón social:' },
          { text: getWorkerField('company') }
        ],
        [
          { text: 'CIF:' },
          { text: getWorkerField('vat') }
        ],
      ]
    },
    fontSize: 10
  });
  items.push({ text: '', margin: [0,30,0,0] });
  items.push({ text: 'Descripción del aviso:'.toUpperCase(), bold: true });
  items.push({ text: '', margin: [0,10,0,0] });
  items.push({ text: getField('Notice description'), fontSize: 10 });
  items.push({ text: '', margin: [0,30,0,0] });
  items.push({ text: 'Resolución de incidencia:'.toUpperCase(), bold: true });
  items.push({ text: '', margin: [0,10,0,0] });
  items.push({ text: getField('Incidence resolution'), fontSize: 10 });
  let remarks = getField('Remark');
  if(remarks) {
    if(Array.isArray(remarks) && remarks.length>0) {
      items.push({ text: '', margin: [0,10,0,0] });
      items.push({ text: `Anotaciones añadidas al servicio`, fontSize: 12 });
      items.push({ text: '', margin: [0,5,0,0] });
      remarks.forEach(remark => {
        items.push({ text: `Defecto: ${remark.Type_Remark ? remark.Type_Remark : '...'} / Fecha: ${remark.Date_Remark ? moment(remark.Date_Remark).format('DD/MM/YYYY') : '...'}`, fontSize: 10 });
        items.push({ text: '', margin: [0,5,0,0] });
      });
    }
  }
  items.push({ text: '', margin: [0,30,0,0] });
  items.push({ text: 'Nombre y firma cliente:'.toUpperCase(), bold: true });
  items.push({ text: '', margin: [0,10,0,0] });
  items.push({ text: getField('Customer_Name'), fontSize: 10 });
  items.push({ text: '', margin: [0,10,0,0] });
  if(fields['Signature']) {
    items.push({ image: `${fields['Signature']}`, width: 150, height: 50, margin: [0,20,0,0] });
  } else {
    items.push({ text: '', margin: [0,20,0,0] });
  }
  items.push({ text: '', margin: [0,10,0,0] });
  return {
    layout: 'noBorders',
    table: {
      widths: ['*'],
      body: [
        [items]
      ]
    }
  };
};

export default model;
