import moment from 'moment';

const model = ({ task, worker, fields }) => {
  let items = [];

  const getTaskField = (fieldId) => {
    if(task[fieldId]) {
      return task[fieldId];
    } else {
      return '...';
    }
  };

  const getWorkerField = (fieldId) => {
    if(worker[fieldId]) {
      return worker[fieldId];
    } else {
      return '...';
    }
  };

  const getField = (fieldId) => {
    if(fields[fieldId]) {
      return fields[fieldId];
    } else {
      return '...';
    }
  };

  const getDate = (from, fieldId) => {
    if(from[fieldId]) {
      return moment(from[fieldId]).format('DD/MM/YYYY');
    } else {
      return '...';
    }
  };

  let fullAddress = [];
  if(task['Address']) {
    fullAddress.push(getTaskField('Address'))
  }
  if(task['Address_2']) {
    fullAddress.push(getTaskField('Address_2'))
  }
  if(task['Post_Code']) {
    fullAddress.push(getTaskField('Post_Code'))
  }
  if(task['City']) {
    fullAddress.push(getTaskField('City'))
  }

  items.push({ text: '', margin: [0,10,0,0] });
  items.push({
    table: {
      widths: ['*', '*'],
      body: [
        [
          { text: 'Código de cliente primagas'.toUpperCase() },
          { text: getTaskField('Customer_No') }
        ],
        [
          { text: 'Dirección'.toUpperCase() },
          { text: fullAddress.join(', ') }
        ],
        [
          { text: 'Nombre'.toUpperCase() },
          { text: getTaskField('Name') }
        ],
        [
          { text: 'Nº Instalación'.toUpperCase() },
          { text: getTaskField('Installation_No') }
        ],
        [
          { text: 'Nº pedido'.toUpperCase() },
          { text: getTaskField('No') }
        ],
        [
          { text: 'Fecha'.toUpperCase() },
          { text: getField('Execution_Date_first_line') }
        ],
      ],
    },
    fontSize: 9,
		layout: {
			hLineWidth: function (i, node) {
				return 0.5;
			},
			vLineWidth: function (i, node) {
				return 0.5;
			},
			hLineColor: function (i, node) {
				return 'gray';
			},
			vLineColor: function (i, node) {
				return 'gray';
			},
		}
  });
  items.push({ text: '', margin: [0,30,0,0] });
  items.push({ text: 'Checklist mantenimiento preventivo'.toUpperCase(), bold: true });
  items.push({ text: '', margin: [0,10,0,0] });
  items.push({
    table: {
      widths: [15, '*', '*'],
      body: [
        [
          { text: '1.', alignment: 'center' },
          { text: 'Verificación del estado de elementos de la instalación en sus partes visibles' },
          { text: '' }
        ],
        [
          { text: '' },
          { text: 'Pintura' },
          { text: getField('Paint') }
        ],
        [
          { text: '' },
          { text: 'Cerramiento' },
          { text: getField('Enclosure') }
        ],
        [
          { text: '' },
          { text: 'Anclajes y cimentaciones' },
          { text: getField('Anchors and foundations') }
        ],
        [
          { text: '' },
          { text: 'Placas de prohibido fumar y nº de teléfono de emergencias' },
          { text: getField('No smoking plates and emergency telephone number') }
        ],
        [
          { text: '' },
          { text: 'Funcionamiento de manómetros (0/20bar) (0/6bar)' },
          { text: getField('Pressure gauge operation (0 / 20bar) (0 / 6bar)') }
        ],
        [
          { text: '2.', alignment: 'center' },
          { text: 'Comprobación de la estanqueidad: Depósito – Valvulería' },
          { text: getField('Deposit - Valves') }
        ],
        [
          { text: '' },
          { text: 'Comprobación estanqueidad:  Retorno fase gaseosa y fase líquida' },
          { text: getField('Gaseous phase and Liquid phase return') }
        ],
        [
          { text: '3.', alignment: 'center' },
          { text: 'Maniobrabilidad de las válvulas' },
          { text: getField('Maneuverability_of_the_valves') }
        ],
        [
          { text: '4.', alignment: 'center' },
          { text: 'Verificación de ausencia de material no autorizado o vegetación dentro del centro de almacenamiento' },
          { text: getField('Verification of absence of unauthorized material or vegetation inside the storage center') }
        ],
        [
          { text: '5.', alignment: 'center' },
          { text: 'Verificación del material contra incendios' },
          { text: getField('Fire Equipment Verification') }
        ],
        [
          { text: '6.', alignment: 'center' },
          { text: 'Verificación del cumplimiento de distancias de seguridad' },
          { text: getField('Verification of compliance with safety distances') }
        ],
      ],
    },
    fontSize: 9,
		layout: {
			hLineWidth: function (i, node) {
				return 0.5;
			},
			vLineWidth: function (i, node) {
				return 0.5;
			},
			hLineColor: function (i, node) {
				return 'gray';
			},
			vLineColor: function (i, node) {
				return 'gray';
			},
		}
  });
  items.push({ text: '', margin: [0,20,0,0], pageBreak: 'before' });
  items.push({
    table: {
      widths: [15, '*', '*'],
      body: [
        [
          { text: '7.', alignment: 'center' },
          { text: 'Revisión de la bomba y engrase' },
          { text: getField('Pump check and grease') }
        ],
        [
          { text: '8.', alignment: 'center' },
          { text: 'Revisión del filtro' },
          { text: getField('Filter Review') }
        ],
        [
          { text: '9.', alignment: 'center' },
          { text: 'Revisión del estado de las mangueras y fecha fabricación' },
          { text: getField('Hose condition check') + ' - ' +  getDate(fields, 'Hose condition check - Manufacturing date') }
        ],
        [
          { text: '' },
          { text: 'Cambio de manguera (Máximo 5 años)' },
          { text: getField('Hose change') }
        ],
        [
          { text: '10.', alignment: 'center' },
          { text: 'Revisión del boquerel' },
          { text: getField('Nozzle Check') }
        ],
        [
          { text: '11.', alignment: 'center' },
          { text: 'Revisión del break away' },
          { text: getField('Break Away Review') }
        ],
        [
          { text: '12.', alignment: 'center' },
          { text: 'Verificación estado de instalación eléctrica' },
          { text: getField('Verification of electrical installation status') }
        ],
      ],
    },
    fontSize: 9,
		layout: {
			hLineWidth: function (i, node) {
				return 0.5;
			},
			vLineWidth: function (i, node) {
				return 0.5;
			},
			hLineColor: function (i, node) {
				return 'gray';
			},
			vLineColor: function (i, node) {
				return 'gray';
			},
		}
  });
  items.push({ text: '', margin: [0,30,0,0] });
  items.push({ text: 'Observaciones'.toUpperCase(), bold: true });
  items.push({ text: '', margin: [0,10,0,0] });
  items.push({
    table: {
      widths: ['*'],
      body: [
        [
          { text: getField('Observations - autogas'), margin: [5, 15, 5, 15] },
        ],
      ],
    },
    fontSize: 10,
		layout: {
			hLineWidth: function (i, node) {
				return 0.5;
			},
			vLineWidth: function (i, node) {
				return 0.5;
			},
			hLineColor: function (i, node) {
				return 'gray';
			},
			vLineColor: function (i, node) {
				return 'gray';
			},
		},
    margin: [0,0,0,0]
  });
  let remarks = getField('Remark');
  if(remarks && ["MTO EXT 1A","MTO EXT 2A"].indexOf(task.Service_Order_Type) !== -1) {
    if(Array.isArray(remarks) && remarks.length>0) {
      items.push({ text: '', margin: [0,10,0,0] });
      items.push({ text: `Anotaciones añadidas al servicio`, fontSize: 12 });
      items.push({ text: '', margin: [0,5,0,0] });
      remarks.forEach(remark => {
        items.push({ text: `Defecto: ${remark.Type_Remark ? remark.Type_Remark : '...'} / Fecha: ${remark.Date_Remark ? moment(remark.Date_Remark).format('DD/MM/YYYY') : '...'}`, fontSize: 10 });
        items.push({ text: '', margin: [0,5,0,0] });
      });
    }
  }
  items.push({ text: '', margin: [0,30,0,0] });
  items.push({ text: 'Firma cliente'.toUpperCase(), bold: true, alignment: 'center' });
  if(fields['Signature']) {
    console.log(fields);
    items.push({ image: `${fields['Signature']}`, width: 150, height: 50, margin: [200,0,0,0] });
    items.push({ canvas: [{ type: 'line', x1: 155, y1: 5, x2: 355, y2: 5, lineWidth: 1, color: '#ccc' }], margin: [0, 40, 0, 20] });
  } else {
    items.push({ canvas: [{ type: 'line', x1: 155, y1: 5, x2: 355, y2: 5, lineWidth: 1, color: '#ccc' }], margin: [0, 40, 0, 20] });
    items.push({ text: '', margin: [0,20,0,0] });
  }
  items.push({
    table: {
      widths: ['*', '*'],
      body: [
        [
          { text: 'Inspeccionado por'.toUpperCase() },
            { text: 'Empresa instaladora'.toUpperCase() },
        ],
        [
          { stack: [
            { text: 'Nombre y apellidos', margin: [0,10,0,0] },
            { text: getWorkerField('fullName'), margin: [0,75,0,0] },
          ] },
          { stack: [
            { text: 'Razón Social y N.º registro', margin: [0,10,0,0] },
            { text: getWorkerField('company') + ' / ' + getField('registry number'), margin: [0,75,0,0] },
          ] }
        ],
      ],
    },
    fontSize: 10,
		layout: {
			hLineWidth: function (i, node) {
				return 0.5;
			},
			vLineWidth: function (i, node) {
				return 0.5;
			},
			hLineColor: function (i, node) {
				return 'gray';
			},
			vLineColor: function (i, node) {
				return 'gray';
			},
		},
    margin: [50,0,50,0]
  });

  return {
    layout: 'noBorders',
    table: {
      widths: ['*'],
      body: [
        [items]
      ]
    }
  };
};

export default model;
