import React, { Component } from 'react';
import Parse from 'parse';
import { Row, Col, Card, Form, Icon, Input, Button, Divider, message } from 'antd';
import { Link } from "react-router-dom";
import { BlankPage } from '../../layout/BlankPage/BlankPage';
import logo from '../../assets/images/primagas_logo.png';

import './forgot-password.css';

const success = () => {
  message.success('Se ha enviado un correo de recuperación a tu email.');
};

const error = (text) => {
  message.error(text);
};

class ForgotFormForm extends React.Component {
  handleSubmit = e => {
    e.preventDefault();
    const { validateFields } = this.props.form;
    validateFields((err, values) => {
      if (!err) {
        const { email } = values;
        Parse.User.requestPasswordReset(email)
        .then(() => {
          success();
          window.location.href = window.location.origin;
        }).catch((error) => {
          error('No se ha podido enviar el email.')
        });
      } else {
        error('Rellena los datos!');
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={(e) => this.handleSubmit(e)} className="login-form">
        <Form.Item>
          {getFieldDecorator('email', {
            rules: [{ required: true, message: 'Por favor, introduce tu email!' }],
          })(
            <Input
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="email"
            />,
          )}
        </Form.Item>
        <Form.Item>
          <Button block htmlType="submit">
            Recuperar contraseña
          </Button>
          <a href="/">Volver al inicio de sesión</a>
        </Form.Item>
      </Form>
    );
  }
}

const WrappedNormalForgotForm = Form.create({ name: 'normal_login' })(ForgotFormForm);

export class ForgotPage extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
  console.log('Login render', this);
    const { I18N, user } = this.props;
    return (
      <BlankPage {...this.props}>
        <Row>
          <Col xs={{ span: 1 }} lg={{ span: 9 }}></Col>
          <Col xs={{ span: 22 }} lg={{ span: 6 }} className="login-wrapper">
            <Card className="login-container">
              <img src={logo} width="100%"/>
              <Divider/>
              <WrappedNormalForgotForm />
            </Card>
          </Col>
          <Col xs={{ span: 1 }} lg={{ span: 9 }}></Col>
        </Row>
      </BlankPage>
    )
  }
}
