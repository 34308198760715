import React, { Component } from 'react';
import { createBrowserHistory } from 'history';
import { Router, Switch, Route } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import Parse from 'parse';

import i18n from './i18n/i18n';
import ScrollToTop from './components/ScrollToTop';

import { LoginPage } from './pages/login/login';
import { ForgotPage } from './pages/forgot-password/forgot-password';
import { HomePage } from './pages/home/home';
import { AgendaPage } from './pages/agenda/agenda';
import { UsersPage } from './pages/users/users';
import { TaskPage } from './pages/task/task';
import { AppsPage } from './pages/apps/apps';
import { MaterialRequest } from './pages/material-request/material-request';
import { Materials } from './pages/materials/materials';
import { Stock } from './pages/stock/stock'
import { APP_VERSION, PARSE_APP_ID, PARSE_JS_KEY, PARSE_SERVER_URL, ADMIN_ROLE, TECH_ROLE } from './common/config';

import './utils/start';
import './App.css';

const history = createBrowserHistory();

Sentry.init({
  release: `primagas-site@${APP_VERSION}`,
  dsn: "https://24e23d7f36b34c95871c2bd076437c2d@o283122.ingest.sentry.io/5357254"
});

Parse.initialize(PARSE_APP_ID, PARSE_JS_KEY, 'CaFcHfMhPkSpUrWuZw3y6B8DbGdJfNjQmSq');
Parse.serverURL = PARSE_SERVER_URL;

let currentUser = Parse.User.current();
//console.log('currentUser', currentUser);

class App extends Component {
  constructor(props) {
    super(props);
    const currentPage = history.location.pathname;
    this.state = {
      firstInit: false,
      session: null,
      language: 'es',
      currentPage
    };
  }

  async componentDidMount() {
    if(!this.state.firstInit){
      let session = await Parse.Session.current()
        .then(result => result)
        .catch(err => null);
      
      this.setState({
        firsInit: true,
        session,
      })
    }
  }

  handleMenuChange = (e) => {
    //console.log('click ', e);
    if(e.key.indexOf('logout') !== -1) {
      Parse.User.logOut().then(result => {
        localStorage.removeItem(`Parse/${PARSE_APP_ID}/currentUser`);
        window.location.href = window.location.origin;
      });
      return;
    }

    if(e.key.indexOf('language:') !== -1) {
      let lang = e.key.replace('language:', '');
      //console.log('lang ', lang);
      this.setState({
        language: lang,
      });
      return;
    }

    this.setState({
      currentPage: e.key,
    });
    return;
  }

  render() {
    //console.log('App Render', this);
    const { language, session } = this.state;

    const I18N = i18n(language);

    let menu = [];
    if(session) {
      const role = currentUser.get('role');
      //console.log('role', role);
      //console.log('ACL', currentUser.get('ACL'));
      //Admin
      if(role.id === ADMIN_ROLE) {
        menu = [
          {
            path: '/',
            name: I18N.Dashboard,
            icon: 'home'
          },
          {
            path: '/users/',
            name: I18N.Users,
            icon: 'user'
          },
          {
            path: '/materials/',
            name: I18N.Materials,
            icon: 'solution'
          },
          {
            path: '/stock/',
            name: I18N.Stock,
            icon: 'book'
          }
        ];
      };
      //Tech
      if(role.id === TECH_ROLE) {
        menu = [
          {
            path: '/',
            name: I18N.Dashboard,
            icon: 'home'
          },
          {
            path: '/agenda/',
            name: I18N.Agenda,
            icon: 'calendar'
          },
          {
            path: '/material-request/',
            name: I18N.MaterialRequest,
            icon: 'solution'
          }
        ];
      };
    }

    const passProps = (props) => {
      return {
        ...props,
        I18N,
        user: currentUser,
        menu: menu,
        language: language,
        onMenuChange: this.handleMenuChange
      };
    };

    const languageName = (lang) => {
      if(lang === 'en') {
        return 'English'
      }
      if(lang === 'es') {
        return 'Español'
      }
    }

    const About = (props) => (
      <div style={{ background: '#fff', padding: 24, minHeight: 280 }}>
        <h1>{props.I18N.About}</h1>
      </div>
    );

    const Account = (props) => (
      <div style={{ background: '#fff', padding: 24, minHeight: 280 }}>
        {props.I18N.Account}
      </div>
    );

    const AuthorizedAdminRouter = () => (
      <Router onUpdate={() => window.scrollTo(0, 0)} history={history}>
        <ScrollToTop>
          <Switch>
            <Route path="/" exact render={(props) => <HomePage isAdmin={true} {...passProps(props)} />} />
            <Route path="/task/" render={(props) => <TaskPage isAdmin={true} {...passProps(props)} />} /> <Route path="/account/" render={(props) => <Account {...passProps(props)} />} />
            <Route path="/users/" render={(props) => <UsersPage {...passProps(props)} />} />
            <Route path="/materials/" render={(props) => <Materials {...passProps(props)} />} />
            <Route path="/stock/" render={(props) => <Stock {...passProps(props)}/>} />
            <Route path="/account/" render={(props) => <Account {...passProps(props)} />} />
            <Route path="/apps/" render={(props) => <AppsPage {...passProps(props)} />} />
          </Switch>
        </ScrollToTop>
      </Router>
    );

    const AuthorizedTechRouter = () => (
      <Router onUpdate={() => window.scrollTo(0, 0)} history={history}>
        <Switch>
          <Route path="/" exact render={(props) => <HomePage {...passProps(props)} />} />
          <Route path="/agenda/" render={(props) => <AgendaPage {...passProps(props)} />} />
          <Route path="/material-request/" render={(props) => <MaterialRequest {...passProps(props)}/>} />
          <Route path="/task/" render={(props) => <TaskPage {...passProps(props)} />} /> <Route path="/account/" render={(props) => <Account {...passProps(props)} />} />
          <Route path="/account/" render={(props) => <Account {...passProps(props)} />} />
          <Route path="/apps/" render={(props) => <AppsPage {...passProps(props)} />} />
        </Switch>
      </Router>
    );

    const PublicRouter = () => (
      <Router onUpdate={() => window.scrollTo(0, 0)} history={history}>
        <Switch>
          <Route path="/" exact render={(props) => <LoginPage {...passProps(props)} />} />
          <Route path="/recovery/" render={(props) => <ForgotPage {...passProps(props)} />} />
        </Switch>
      </Router>
    );

    if(currentUser) {
      const role = currentUser.get('role');
      //console.log(role)
      if(role.id === ADMIN_ROLE) return (<AuthorizedAdminRouter/>);
      if(role.id === TECH_ROLE) return (<AuthorizedTechRouter/>);
      return (<PublicRouter/>);
    } else {
      return (<PublicRouter/>);
    }
  }
}

export default App;
