/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Form, Icon, Input, InputNumber, Button, Select, Checkbox, DatePicker, Row, Col, message, Divider, Upload } from 'antd';
import { find } from 'lodash';
import moment from 'moment';
import Parse from 'parse';
import generatePdf from '../../utils/generatePdf'
import { stringToJs } from '../../utils/utils';
import SchemaField from './components/Schema';

const { Option } = Select;

const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('Solo puedes subir archivos JPG/PNG!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

class GlobalForm extends React.Component {

    constructor(props) {
      super(props)
      this.state = {
        loaded: false,
        submitting: false,
        currentUser: Parse.User.current()
      }
    }

    componentDidMount() {
    }

    createPDF = async ({ type, task, worker, data }) => {
      const { storage, fields } = this.props;

      let parsedFields = {};
      Object.keys(data).forEach(key => {
        let field = find(fields, { id: key })
        if(field) {
          switch (field.type) {
            case 'select':
              let options = field.options ? stringToJs(field.options) : null;
              parsedFields[key] = options[data[key]];
              break;
            case 'signature':
              parsedFields[key] = data[key] ? data[key].file.url : null;
              break;
            case 'schema':
              if(data[key]) {
                let schemaParsedFields = [];
                data[key].forEach((item, i) => {
                  let schemaField = {};
                  Object.keys(item).forEach(subkey => {
                    let subfield = find(field.schema, { id: subkey })
                    switch (subfield.type) {
                      case 'select':
                        let options = subfield.options ? stringToJs(subfield.options) : null;
                        schemaField[subkey] = options[item[subkey]];
                        break;
                      default:
                        schemaField[subkey] = item[subkey];
                    }
                  });
                  schemaParsedFields.push(schemaField)
                });
                parsedFields[key] = schemaParsedFields;
              }
              break;
            default:
              parsedFields[key] = data[key];
          }
        }
      });

      return new Promise(async (resolve) => {
        try {

          const { pdfData, onlyData, fileInfo } = await generatePdf({ type ,task, worker, fields: parsedFields })
          //console.log('pdf', type);

          let object = {
            owner: { __type: 'Pointer', className: '_User', objectId: this.state.currentUser.id },
            task: { __type: 'Pointer', className: 'Task', objectId: task.objectId },
            fileName: `${task.Customer_No}_${task.No}_${task.Installation_No}_${fileInfo.title.split(' ').join('_')}-${moment().format('DD-MM-YYYY')}.pdf`,
            url: pdfData,
            type: 'autodoc'
          };
          // console.log('pdf object', object);
          // let inserted = false;
          const Media = Parse.Object.extend('Media');
          const media = new Media();
          let inserted = await media.save(object)
          .then(result => result.toJSON())
          .catch(err => {
            //console.log(err)
            return null;
          });
          console.log('pdf inserted', inserted);
          if(!inserted) {
            message.error('No se ha generado el documento');
            resolve(false);
          }
          resolve(true);
        } catch (error) {
          //console.log(error)
          resolve(false);
        }
      });
    }

    submit = e => {
      e.preventDefault();
      let self = this;
      const { task, user, fields, storage } = this.props;
      const { taskData } = this.state;
      //console.log(taskData);
      self.setState({ submitting: true }, () => {
        self.props.form.validateFields(async (error, formData) => {
          // console.log('validateFields', {error, formData});
          if(!error){
            message.info('Guardando');
            if(task.Service_Order_Type === 'CG EXT' && task.Status === 'On_Hold') {
              message.info('Autogenerando archivos');
              let fileResult = await self.createPDF({ type: 'CGEXT', task, worker: this.state.currentUser.toJSON(), data: formData })
              if(!fileResult) {
                message.error('Error sububiendo archivo');
              } else {
                message.success('Petición de depósito realizada');
              }
            }
            if(task.Service_Order_Type === 'S24H') {
              message.info('Autogenerando archivos');
              let fileResult = await self.createPDF({ type: 'S24H', task, worker: this.state.currentUser.toJSON(), data: formData })
              if(!fileResult) {
                message.error('Error sububiendo archivo');
              }
            }
            if(task.Service_Order_Type === 'REPAR') {
              message.info('Autogenerando archivos');
              let fileResult = await self.createPDF({ type: 'REPAR', task, worker: this.state.currentUser.toJSON(), data: formData })
              if(!fileResult) {
                message.error('Error sububiendo archivo');
              }
            }
            if(['MTO EXT 1A', 'MTO EXT 2A', 'REV EXT 5A', 'REV EXT 2A'].indexOf(task.Service_Order_Type) !== -1) {
              message.info('Autogenerando archivos');
              if(formData.PDF_Type === 'Autogas') {
                let fileResult = await self.createPDF({ type: 'AUTOGAS', task, worker: this.state.currentUser.toJSON(), data: formData })
                if(!fileResult) {
                  message.error('Error sububiendo archivo');
                }
              }
              if(formData.PDF_Type === 'GLP') {
                let fileResult = await self.createPDF({ type: 'GLP', task, worker: this.state.currentUser.toJSON(), data: formData })
                if(!fileResult) {
                  message.error('Error sububiendo archivo');
                }
              }
            }


            let parsedFields = {};
            Object.keys(formData).forEach(key => {
              let field = find(fields, { id: key })
              if(field) {
                parsedFields[key] = formData[key];
                if(field.type === 'signature') {
                  parsedFields[key] = formData[key] ? formData[key].file.url : null;
                }
                if(field.type === 'date') {
                  parsedFields[key] = formData[key] ? formData[key]._d : null;
                }
              }
            });
            // console.log(parsedFields);

            let newData = {
              owner: { __type: 'Pointer', className: '_User', objectId: this.state.currentUser.id },
              task: { __type: 'Pointer', className: 'Task', objectId: task.objectId },
              Data: parsedFields,
            };
            // console.log('TaskData to submit', newData);

            let serviceId = task.Service_Order_Type;
            if(serviceId === 'CG EXT' && task.Status === 'On_Hold') {
                serviceId = 'CG EXT PDF';
            }

            let dataExist = false;
            if(serviceId === 'CG EXT') {
              dataExist = true;
            }

            const TaskData = Parse.Object.extend('TaskData');
            let taskData = null;

            if(dataExist) {
              const taskDataQuery = new Parse.Query(TaskData);
              taskDataQuery.equalTo('task', { __type: 'Pointer', className: 'Task', objectId: task.objectId })
              taskData = await taskDataQuery.first();
            }

            if(!taskData) {
              taskData = new TaskData();
            }


            const Task = Parse.Object.extend('Task');
              const query = new Parse.Query(Task);
              query.equalTo('objectId', task.objectId);
              query.first()
              .then((taskObj) => {

                message.success('Datos almacenados en la plataforma');

                if(serviceId === 'CG EXT PDF') {
                  taskObj.set('Status', 'In_Process');
                  taskObj.unset('Completed');
                  taskObj.unset('completedAt');
                } else {
                  taskObj.set('Status', 'Finished');
                  taskObj.set('Completed', true);
                  taskObj.set('completedAt', new Date());
                }
                if(parsedFields.Unit_Cost_LCY) {
                  taskObj.set('Unit_Cost_LCY', parseFloat(parsedFields.Unit_Cost_LCY));
                }

                taskObj.save()
                .then(result => {

                  message.success('Estado de la tarea actualizado');

                  taskData.save(newData)
                    .then(result => {
                      self.props.onSubmited(true);
                      self.setState({ submitting: false });
                    })
                    .catch(err => {

                      //console.log(err)
                      message.error('Error de actualización de estado de la tarea');
                      self.props.onSubmited(true);
                      self.setState({ submitting: false });

                    });

                })
                .catch(err => {

                  message.error('Error de actualización de estado de la tarea');
                  self.props.onSubmited(true);
                  self.setState({ submitting: false });

                });

            })
            .catch(err => {

              //console.log(err)
              message.error('Error almacenando datos en la plataforma');
              self.props.onSubmited(true);
              self.setState({ submitting: false });

            });

          } else {
            self.setState({ submitting: false });
          }

        });
      });

    }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      //console.log(values)
      if (!err) {
        console.log('Received values of form: ', values);
      }
    });
  };

  visibility(field) {
    const { fields = [] } = this.props;
    const { getFieldsValue } = this.props.form;
    //console.log('visibleCondition', field.visible);
    if(field.visible) {
      // eslint-disable-next-line no-new-func
      let fn = new Function("values", `let op = ${field.visible}; return op;`);
      let values = {
        ...getFieldsValue(fields.length ? fields.map(f => f.id) : [])
      };
      let result = fn(values);
      if(result === true || result === false) {
        return result;
      }
      return true;
    }
    return true;
  }

  rewriteString(str) {
    const {
      task,
    } = this.props;
    if(str && str.indexOf('{{') !== -1 && str.indexOf('}}') !== -1) {
      str = str.replace('{{', '').replace('}}', '');
      //console.log('stringToJs', `return ${str};`);
      // eslint-disable-next-line no-new-func
      let fn = new Function('task', `return ${str};`);
      let result = fn(task);
      //console.log('stringToJs result', result);
      return result;
    } else {
      return str;
    }
  }

  renderField(props){
    const { completed, field } = props;
    const { id, type, label, placeholder, options, schema, value } = field;
    const { setFieldsValue, getFieldValue } = this.props.form;
    console.log('renderField', props)
    switch (type) {
      case 'header':
        return (
          <h2>{label}</h2>
        );
      case 'text':
        if(completed) return value ? value : '...';
        return (
          <Input
            placeholder={placeholder ? placeholder : 'Introducir texto'}
          />
        );
      case 'int':
        if(completed) return value ? value : '...';
        return (
          <InputNumber
            placeholder={placeholder ? placeholder : 'Introducir númerro'}
            style={{width: '100%'}}
          />
        );
      case 'number':
        if(completed) return value ? value : '...';
        return (
          <InputNumber
            placeholder={placeholder ? placeholder : 'Introducir número'}
            style={{width: '100%'}}
          />
        );
      case 'select':
        if(completed) return value ? options[value] : '...';
        return (
          <Select
            showSearch
            placeholder={placeholder ? placeholder : 'Seleccionar opción'}
            filterOption={(input, option) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {Object.keys(options).map((option, i) => (
              <Option key={option} value={option}>{options[option]}</Option>
            ))}
          </Select>
        );
      case 'date':
        if(completed) return value ? moment(value.iso).format('DD/MM/YYYY') : '...';
        return (
          <DatePicker
            placeholder={placeholder ? placeholder : 'Seleccionar fecha'}
            format={'DD/MM/YYYY'}
            style={{width: '100%'}}
          />
        );
      case 'schema':
        let renderValue = (f,val) => {
          switch (f.type) {
            case 'select':
              if(!val) return '...'
              let options = f.options ? stringToJs(f.options) : null;
              return options ? `(${val}) ${options[val]}` : '...'
            case 'date':
              if(!val) return '...'
              let date = val ? val : null;
              if(date.iso) {
                date = date.iso
              }
              return moment(date).format('DD/MM/YYYY')
            default:
              if(!val) return '...'
              return val;
          }
        };
        if(completed) {
          return value && typeof value === 'object' && value.length > 0 ? (
            <div key={id}>
              <Row>
                {Object.keys(value[0]).map((key, c) => {
                  return (
                    <Col key={c} span={8}>
                      <small key={`${c}`}><b>{find(schema, {id: key}).label}:</b></small>
                    </Col>
                  );
                })}
              </Row>
              <hr/>
              {value.map((item, i) => {
                return (
                  <div key={i}>
                    <Row>
                      {Object.keys(item).map((key, c) => {
                        return (
                          <Col key={c} span={8}>
                            <small key={`${i}-${c}`}>{renderValue(find(schema, {id: key}), item[key])}</small>
                          </Col>
                        );
                      })}
                    </Row>
                  </div>
                );
              })}
            </div>
          ) : '...';
        }
        let value = getFieldValue(id);
        return (
          <div>
            {value && typeof value === 'object' && value.length > 0 ? (
              <div>
                <Row>
                  {Object.keys(value[0]).map((key, c) => {
                    //console.log(key, schema)
                    return (
                      <Col key={c} span={7}>
                        <small key={`${c}`}><b>{find(schema, {id: key}).label}</b></small>
                      </Col>
                    );
                  })}
                  <Col key={'del'} span={3}>
                    <small key={`del`}><b>{'Borrar'}</b></small>
                  </Col>
                </Row>
                <hr/>
                {value.map((item, i) => {
                  return (
                    <div key={i}>
                      <Row>
                        {Object.keys(item).map((key, c) => {
                          return (
                            <Col key={c} span={7}>
                              <small key={`${i}-${c}`}>{renderValue(find(schema, {id: key}), item[key])}</small>
                            </Col>
                          );
                        })}
                        <Col key={'del'} span={3}>
                          <Button icon="delete" type="link" onClick={() => {
                            value.splice(i, 1);
                            setFieldsValue({
                              [field.id]: value.length === 0 ? null : value
                            })
                          }} />
                        </Col>
                      </Row>
                    </div>
                  );
                })}
              </div>
            ) : null}
            <input type="hidden"/>
          </div>
        );


        // return (
        //   <Input
        //     placeholder={placeholder ? placeholder : 'Introducir texto'}
        //     disabled={true}
        //   />
        // );
      case 'signature':
        if(completed) {
          return value ? (
            <img src={value.indexOf('data:image') !== -1 ? value : `data:image/jpeg;base64,${value}`} alt={'signature'} width="250" height="auto"/>
          ) : 'Sin firmar';
        }
        return null;
      default:
        return null;
    }

  }

  renderCompleted() {
    const { fields, taskData } = this.props;

    //console.log('fields', fields)
    //console.log('taskData', taskData)



    const visibilityCompleted = (field) => {
      const { taskData } = this.props;
      //console.log('visibleCondition', field.visible);
      if(field.visible) {
        // eslint-disable-next-line no-new-func
        let fn = new Function("values", `let op = ${field.visible}; return op;`);
        let values = {
          ...taskData.Data
        };
        let result = fn(values);
        if(result === true || result === false) {
          return result;
        }
        return true;
      }
      return true;
    }

    const renderItem = (field) => {
      field.options = field.options ? stringToJs(field.options) : {};
      const { id, type, label, options, schema } = field;
      const value = taskData ? taskData.Data[id] : null;
      // console.log(field, value)
      switch (type) {
        case 'header':
          return (
            <h2>{label}</h2>
          );
        case 'text':
          return value ? value : '...';
        case 'int':
          return typeof value === 'number' || value ? value : '...';
        case 'number':
          return typeof value === 'number' || value ? value : '...';
        case 'select':
          return value ? options[value] : '...';
        case 'date':
          return value ? moment(value.iso).format('DD/MM/YYYY') : '...';
        case 'schema':
          let renderValue = (f,val) => {
            switch (f.type) {
              case 'text':
                return val ? val : '...';
              case 'int':
                return typeof val === 'number' || value ? val : 0;
              case 'number':
                return typeof val === 'number' || value ? val : 0;
              case 'select':
                f.options = f.options ? stringToJs(f.options) : {};
                console.log(field, value)
                return val ? f.options[val] : '...';
              case 'date':
                return val && val.iso ? moment(val.iso).format('DD/MM/YYYY') : '...';
              default:
                return val;
            }
          };
          return value && typeof value === 'object' && value.length > 0 ? (
            <div>
              <Row>
                {schema.map((subfield, c) => {
                  return (
                    <Col key={c} span={8}>
                      <small key={`${c}`}><b>{subfield.label}:</b></small>
                    </Col>
                  );
                })}
              </Row>
              <hr/>
              {value.map((item, i) => {
                return (
                  <div key={i}>
                    <Row>
                      {schema.map((subfield, c) => {
                        console.log('subfield', subfield.id, item[subfield.id])
                        return (
                          <Col key={c} span={8}>
                            <small key={`${i}-${c}`}>{renderValue(subfield, item[subfield.id])}</small>
                          </Col>
                        );
                      })}
                    </Row>
                  </div>
                );
              })}
            </div>
          ) : '...';
        case 'signature':
          return value ? (
            <img src={value.indexOf('data:image') !== -1 ? value : `data:image/jpeg;base64,${value}`} alt="signature" width="250" height="auto"/>
          ) : 'Sin firmar';
        default:
          return null;
      }
    };

    return (
      <div>
        {fields.map(field => {
          let visible = visibilityCompleted(field);
          if (!visible) return null;

          //console.log(field)
          return (
            <div key={field.id}>
              <h3>{field.type !== 'header' ? field.label : null}</h3>
              {renderItem(field)}
              <Divider/>
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    //console.log('Form', this)
    const { fields, task, taskData, completed } = this.props;
    const { getFieldDecorator, getFieldValue, setFieldsValue } = this.props.form;
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    if(completed) {
      return this.renderCompleted();
    }
    return (
      <Form onSubmit={this.submit} className="dynamic-form">
        {fields.map(field => {
          let visible = this.visibility(field);
          if (!visible) return null;
          //console.log(field);
          let fieldProps = {};
          let rules = field.rules ? field.rules : [];
          if(field.required) rules = [{ required: field.required, message: 'El campo es obligatorio!'}].concat(rules);
          if([undefined, null, ''].indexOf(field.min) === -1) {
            let minVal = this.rewriteString(field.min);
            let maxVal = this.rewriteString(field.max);
            rules = [{ type: 'number', min: minVal, max: maxVal, message: `El valor debe ser entre ${minVal} y ${maxVal}!`}].concat(rules);
          }
          fieldProps.rules = rules;

          let options = field.options ? stringToJs(field.options) : null;

          let description = null;
          if([undefined, null, ''].indexOf(field.description) === -1) {
            description = this.rewriteString(field.description);
          }
          //console.log(field.id, rules);
          // Set initial value
          let initialValue = null;
          if(field.initialValue) {
            initialValue = stringToJs(field.initialValue);
          }

          if(taskData && taskData['Data']) {
            if(typeof taskData['Data'][field.id] !== 'undefined' || taskData['Data'][field.id] !== null) {
              initialValue = taskData['Data'][field.id];

              if(field.type === 'int' || field.type === 'number') {
                initialValue = initialValue ? initialValue.toString() : null;
              }

              if(field.type === 'date') {
                initialValue = initialValue && initialValue.iso ? moment(initialValue.iso) : null;
              }
            }
          }
          fieldProps.initialValue = initialValue;

          fieldProps.placeholder = field.placeholder;
          if(field.type === 'signature') {
            const imageUrl = getFieldValue(field.id);
            return (
              <Form.Item key={field.id} style={{marginBottom: 0}}>
                <h3>{field.type !== 'header' ? field.label : null}</h3>
                {getFieldDecorator(field.id, fieldProps)(
                  <Upload.Dragger
                    name="files"
                    beforeUpload={beforeUpload}
                    showUploadList={false}
                    action={async (file) => {
                      const base64 = await toBase64(file);
                      file.url = base64
                      return false;
                    }}
                  >
                    {imageUrl ? <img src={imageUrl.file.url} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                  </Upload.Dragger>,
                )}
                {description ? <div><small>{description}</small></div> : null}
                <Divider/>
              </Form.Item>
            );
          }
          if(field.type === 'schema') {
            let value = getFieldValue(field.id);
            return (
              <div key={`field-container-${field.id}`}>
                <Form.Item key={field.id} style={{marginBottom: 0}}>
                  <h3>{field.type !== 'header' ? field.label : null}</h3>
                  {description ? <div><small>{description}</small></div> : null}
                  {getFieldDecorator(field.id, fieldProps)(
                    this.renderField({field: {...field, options, description}, task: task, completed: completed}),
                  )}
                </Form.Item>
                <SchemaField {...{field, task, value}} submitted={(values) => {
                  //console.log('Received: ', values);
                  let newValue = value ? value : [];
                  if(!newValue || !Array.isArray(newValue)) {
                    newValue = []
                  }
                  newValue.push(values)
                  //console.log('Received: ', newValue);
                  setFieldsValue({
                    [field.id]: newValue
                  })
                }}/>
                <Divider/>
              </div>
            );
          }
          return (
            <Form.Item key={field.id} style={{marginBottom: 0}}>
              <h3>{field && field.type !== 'header' ? field.label : null}</h3>
              {getFieldDecorator(field.id, fieldProps)(
                this.renderField({field: {...field, options, description}, task: task, completed: completed}),
              )}
              {description ? <div><small>{description}</small></div> : null}
              <Divider/>
            </Form.Item>
          );
        })}

        <Form.Item>
          <Button type="primary" htmlType="submit" className="login-form-button">
            {task.Service_Order_Type === 'CG EXT' && task.Status === 'On_Hold' ? 'Petición de depósito' : 'Guardar'}
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create({ name: 'dynamic_form' })(GlobalForm);
