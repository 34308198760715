import moment from 'moment';

const model = ({ task, worker, fields }) => {
  let items = [];

  const getTaskField = (fieldId) => {
    if(task[fieldId]) {
      return task[fieldId];
    } else {
      return '...';
    }
  };

  const getWorkerField = (fieldId) => {
    if(worker[fieldId]) {
      return worker[fieldId];
    } else {
      return '...';
    }
  };

  const getField = (fieldId) => {
    if(fields[fieldId]) {
      return fields[fieldId];
    } else {
      return '...';
    }
  };

  const getDate = (from, fieldId) => {
    if(from[fieldId]) {
      return moment(from[fieldId]).format('DD/MM/YYYY');
    } else {
      return '...';
    }
  };

  let fullAddress = [];
  if(task['Address']) {
    fullAddress.push(getTaskField('Address'))
  }
  if(task['Address_2']) {
    fullAddress.push(getTaskField('Address_2'))
  }
  if(task['Post_Code']) {
    fullAddress.push(getTaskField('Post_Code'))
  }
  if(task['City']) {
    fullAddress.push(getTaskField('City'))
  }

  items.push({ text: '', margin: [0,10,0,0] });
  items.push({ text: 'Cliente:'.toUpperCase(), bold: true });
  items.push({ text: '', margin: [0,10,0,0] });
  items.push({
    layout: 'noBorders',
    table: {
      widths: ['*', '*'],
      body: [
        [
          { text: 'Número de cliente:' },
          { text: getTaskField('Customer_No') }
        ],
        [
          { text: 'Nombre:' },
          { text: getTaskField('Name') }
        ],
        [
          { text: 'Población:' },
          { text: getTaskField('City') }
        ],
        [
          { text: 'País:' },
          { text: getTaskField('Country_Region_Code') }
        ],
        [
          { text: 'Dirección:' },
          { text: fullAddress.join(', ') }
        ],
      ]
    },
    fontSize: 10
  });
  items.push({ text: '', margin: [0,30,0,0] });
  items.push({ text: 'Instalador:'.toUpperCase(), bold: true });
  items.push({ text: '', margin: [0,10,0,0] });
  items.push({
    layout: 'noBorders',
    table: {
      widths: ['*', '*'],
      body: [
        [
          { text: 'Empresa instaladora (Razón social):' },
          { text: getWorkerField('company') }
        ],
        [
          { text: 'Nombre del instalador:' },
          { text: getWorkerField('fullName') }
        ],
        [
          { text: 'Número de teléfono:' },
          { text: getWorkerField('phone') }
        ],
      ]
    },
    fontSize: 10
  });
  items.push({ text: '', margin: [0,30,0,0] });
  items.push({ text: 'Persona de contacto:'.toUpperCase(), bold: true });
  items.push({ text: '', margin: [0,10,0,0] });
  items.push({
    layout: 'noBorders',
    table: {
      widths: ['*', '*'],
      body: [
        [
          { text: 'Nombre completo:' },
          { text: getField('Contact person') }
        ],
        [
          { text: 'Número de teléfono:' },
          { text: getField('Phone number') }
        ],
      ]
    },
    fontSize: 10
  });
  items.push({ text: '', margin: [0,30,0,0] });
  items.push({ text: 'Depósito:'.toUpperCase(), bold: true });
  items.push({ text: '', margin: [0,10,0,0] });
  items.push({
    layout: 'noBorders',
    table: {
      widths: ['*', '*'],
      body: [
        [
          { text: 'Tipo:' },
          { text: getField('Type') }
        ],
        [
          { text: 'Capacidad:' },
          { text: getField('Capacity') + ' / ' + getField('Capacity_Orientation') }
        ],
        [
          { text: 'Losa:' },
          { text: getField('Slab') }
        ],
        [
          { text: 'Acceso:' },
          { text: getField('Access') }
        ],
      ]
    },
    fontSize: 10
  });
  items.push({ text: '', margin: [0,30,0,0] });
  items.push({ text: 'Observaciones:'.toUpperCase(), bold: true });
  items.push({ text: '', margin: [0,10,0,0] });
  items.push({ text: getField('Observations'), fontSize: 10 });
  items.push({ text: '', margin: [0,10,0,0] });
  return {
    layout: 'noBorders',
    table: {
      widths: ['*'],
      body: [
        [items]
      ]
    }
  };
};

export default model;
