import React, { Component } from 'react';
import { Row, Col, Table, Modal, Form, Button, Select, Input, Menu, Dropdown, Icon, message } from 'antd';
import Parse from 'parse';
import { find } from 'lodash';
import { CleanLayout } from '../../layout/CleanLayout';

import config from '../../common/config';

const { Option } = Select;
const { confirm } = Modal;

const companies = [
  {
    "Primagas_Id": "80001723",
    "company": "ZONTAK ENERGÍA, SL.",
    "vat" : "B34222364"
  },
  {
    "Primagas_Id": "80001585",
    "company": "INSTAL.LACIONS I SERVEIS JORDI PUIG S.L.",
    "vat" : "B63662548"
  },
  {
    "Primagas_Id": "80000952",
    "company": "MAPEGAS S.L.",
    "vat" : "B78039245"
  }
];

const UserCreateForm = Form.create({ name: 'new_user_modal' })(
  // eslint-disable-next-line
  class extends React.Component {
    state = {
      data: [],
      selectedCompanyId: null
    };

    handleSearch = async (value) => {
      if (value) {
        const Vendor = Parse.Object.extend('Vendor');
        const query = new Parse.Query(Vendor);
        query.fullText('company', value)
        let data = await query.find()
        .then(results => results.map(r => r.toJSON()))
        .catch(err => []);
        console.log('SEARCH RESULTS', data);
        this.setState({ data });
      } else {
        this.setState({ data: [] });
      }
    };

    render() {
      const { data, selectedCompanyId } = this.state;
      const { visible, onCancel, onCreate, form, user } = this.props;
      const { getFieldDecorator } = form;
      console.log(selectedCompanyId);
      const selectedCompany = find(data, {Primagas_Id: this.state.selectedCompanyId});
      console.log(selectedCompany);
      return (
        <Modal
          visible={visible}
          title="Crear un nuevo usuario"
          okText="Guardar"
          cancelText="Cancelar"
          onCancel={onCancel}
          onOk={onCreate}
        >
          <Form layout="vertical">
            <Form.Item label="Nombre completo">
              {getFieldDecorator('fullName', {
                initialValue: user ? user.fullName : null,
                rules: [{ required: true, message: 'Por favor, introduce el nombre!' }],
              })(<Input autoComplete="nope" />)}
            </Form.Item>
            <Form.Item label="Email">
              {getFieldDecorator('email', {
                initialValue: user ? user.contactEmail : null,
                rules: [
                  { type: 'email', message: 'Introduce un email válido!', },
                  { required: true, message: 'Por favor, introduce el email!' }
                ],
              })(<Input autoComplete="nope" type="email" />)}
            </Form.Item>
            <Form.Item label="Empresa">
              {getFieldDecorator('_company', {
                initialValue: user ? user.Primagas_Id : null,
                rules: [{ required: true, message: 'Por favor, seleccione una empresa!' }],
              })(
                <Select
                  showSearch
                  autoComplete="nope"
                  placeholder="Seleciona una empresa"
                  optionFilterProp="children"
                  onSearch={this.handleSearch}
                  onChange={(value) => {
                    this.setState({
                      selectedCompanyId: value
                    })
                  }}
                >
                  {data.map(company => (
                    <Option key={company.objectId} value={company.Primagas_Id}>{company.company}</Option>
                  ))}
                </Select>
              )}
              {getFieldDecorator('vat', {
                initialValue: selectedCompany ? selectedCompany.vat : null
              })(<Input type="hidden" />)}
              {getFieldDecorator('company', {
                initialValue: selectedCompany ? selectedCompany.company : null
              })(<Input type="hidden" />)}
              {getFieldDecorator('Primagas_Id', {
                initialValue: selectedCompany ? selectedCompany.Primagas_Id : null
              })(<Input type="hidden" />)}
              {getFieldDecorator('vendor', {
                initialValue: selectedCompany ? selectedCompany.objectId : null
              })(<Input type="hidden" />)}
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  },
);

export class UsersPage extends Component {
  state = {
    visible: false,
    search: null,
    filters: {},
    data: [],
    pagination: {
      pageSize: 25
    },
    loading: false,
    selectedUser: null,
  };

  componentDidMount() {
    this.fetch();
  }

  onFilterChange(filter, value) {
    console.log({
      ...this.state.filters,
      [filter]: value
    });
    this.setState({
      filters: {
        ...this.state.filters,
        [filter]: value
      }
    });
  }

  clearFilters() {
    this.setState({
      filters: {}
    }, () => {
      this.fetch()
    });
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.fetch({
      pageSize: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
    });
  };

  fetch = async (params = {}) => {
    console.log('params:', this.state.filters);
    this.setState({ loading: true });
    const _User = Parse.Object.extend('_User');
    const query = new Parse.Query(_User);
    query.equalTo('role', { __type: 'Pointer', className: '_Role', objectId: config.TECH_ROLE })
    Object.keys(this.state.filters).forEach(key => {
      if(key === 'Installation_No') {
        query.fullText(key, this.state.filters[key]);
      }
      if(key === 'Status') {
        query.equalTo(key, this.state.filters[key]);
      }
      if(key === 'City') {
        query.equalTo(key, this.state.filters[key]);
      }
      if(key === 'Planned_Service_Date') {
        console.log('Planned_Service_Date', this.state.filters[key].format('YYYY-MM-DD'));
        query.equalTo(key, this.state.filters[key].format('YYYY-MM-DD'));
      }
      if(key === 'New_Service_Date') {
        query.equalTo(key, this.state.filters[key]);
      }
      if(key === 'Tech') {
        query.equalTo(key, { __type: 'Pointer', className: '_User', objectId: this.state.filters[key] });
      }
    });
    if(params.sortOrder === 'ascend') {
      query.ascending(params.sortField);
    }
    if(params.sortOrder === 'descend') {
      query.descending(params.sortField);
    }
    if(params.page>1) {
      query.skip(params.pageSize*(params.page-1));
    }
    query.include(['Tech']);
    query.limit(params.pageSize||this.state.pagination.pageSize);
    query.withCount();
    const response = await query.find();
    if(response) {
      console.log(response)
      let data = response.results.map(r => {
        return {
          ParseClass: r,
          ...r.toJSON()
        }
      });
      console.log(data)
      let pagination = {
        ...this.state.pagination,
        total: response.count
      };
      this.setState({
        loading: false,
        data: data,
        pagination,
      });
    }
  };

  showModal = () => {
    this.setState({ visible: true });
  };

  handleCancel = () => {
    this.setState({ visible: false, selectedUser: null });
  };

  handleCreate = () => {
    let self = this;
    const { selectedUser } = this.state;
    const { form } = this.formRef.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      if(selectedUser) {
        const user = selectedUser.ParseClass;

        user.set('username', values.email);
        user.set('email', values.email);
        user.set('contactEmail', values.email);
        user.set('fullName', values.fullName);
        user.set('vat', values.vat);
        user.set('company', values.company);
        user.set('Primagas_Id', values.Primagas_Id);
        user.set('role', { __type: 'Pointer', className: '_Role', objectId: config.TECH_ROLE });
        user.set('vendor', { __type: 'Pointer', className: 'Vendor', objectId: values.vendor });

        user.save()
        .then(async (user) => {
          // Execute any logic that should take place after the object is saved.
          //alert('Usuario creado');
          self.fetch()
          this.setState({ visible: false });
          message.success('Usuario actualizado correctamente!');
        }, (error) => {
          // Execute any logic that should take place if the save fails.
          // error is a Parse.Error with an error code and message.
          console.log(error);
          message.error('No se ha podido actualizar el usuario!');
        });
      } else {
        console.log('Received values of form: ', values);
        const _User = Parse.Object.extend("_User");
        const user = new _User();

        const customPWD = (length = 25) => {
           let result           = '';
           let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!#?*';
           let charactersLength = characters.length;
           for ( let i = 0; i < length; i++ ) {
              result += characters.charAt(Math.floor(Math.random() * charactersLength));
           }
           return result;
        };

        user.set('username', values.email);
        user.set('password', customPWD());
        user.set('email', values.email);
        user.set('contactEmail', values.email);
        user.set('fullName', values.fullName);
        user.set('vat', values.vat);
        user.set('company', values.company);
        user.set('Primagas_Id', values.Primagas_Id);
        user.set('role', { __type: 'Pointer', className: '_Role', objectId: config.TECH_ROLE });
        user.set('vendor', { __type: 'Pointer', className: 'Vendor', objectId: values.vendor });

        user.save()
        .then(async (user) => {
          // Execute any logic that should take place after the object is saved.
          //alert('Usuario creado');
          Parse.User.requestPasswordReset(values.email);
          self.fetch()
          form.resetFields();
          this.setState({ visible: false });
          message.success('Usuario creado correctamente!');
        }, (error) => {
          // Execute any logic that should take place if the save fails.
          // error is a Parse.Error with an error code and message.
          console.log(error);
          message.error('No se ha podido crear el usuario!');
        });
      }
    });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  render() {
    const { I18N, user } = this.props;
    const { currentPage, loading } = this.state;
    console.log('User render', this);

    const columns = [
      {
        title: 'Id',
        dataIndex: 'Primagas_Id',
        sorter: true,
        render: (field, record) => (<a>{record.objectId}</a>),
        width: '20%',
      },
      {
        title: 'Email',
        dataIndex: 'contactEmail',
        sorter: true,
        render: (field, record) => field,
        width: '20%',
      },
      {
        title: 'Nombre',
        dataIndex: 'fullName',
        render: (field, record) => field,
        sorter: true,
      },
      {
        title: 'Empresa',
        dataIndex: 'company',
        render: (field, record) => field,
        sorter: true,
      },
      {
        title: '',
        render: (field, record) => {
          const menu = (
            <Menu>
              <Menu.Item>
                <a onClick={() => {
                  this.setState({
                    selectedUser: record,
                    visible: true
                  })
                }}>
                  Editar usuario
                </a>
              </Menu.Item>
              <Menu.Item>
                <a onClick={() => {
                  confirm({
                    title: '¿Seguro que deseas borrar este usuario?',
                    content: 'El usuario se eliminará completamentes del sistema y no podrás recuperarlo.',
                    okText: 'Borrar',
                    cancelText: 'Cancelar',
                    onOk: async () => {
                      console.log('OK', record);
                      let user = await record.ParseClass.destroy()
                      .then(r => {
                        message.success('Usuario borrado correctamente!');
                        this.fetch()
                      })
                      .catch(err => {
                        console.log('Error', err)
                        message.error('Error borrando usuario!');
                      });
                    },
                    onCancel() {
                      console.log('Cancel');
                    },
                  });
                }}>
                  Borrar usuario
                </a>
              </Menu.Item>
            </Menu>
          );
          return (
            <Dropdown overlay={menu}>
              <a className="ant-dropdown-link" href="#">
                Acción <Icon type="down" />
              </a>
            </Dropdown>
          );
        },
      },
    ];

    return (
      <CleanLayout {...this.props} title={I18N.Home}>
        <Row>
          <Col span={24}>
            <Button type="primary" onClick={this.showModal} style={{ margin: '24px 0 0 24px' }}>
              Nuevo usuario
            </Button>
            <UserCreateForm
              wrappedComponentRef={this.saveFormRef}
              visible={this.state.visible}
              onCancel={this.handleCancel}
              onCreate={this.handleCreate}
              user={this.state.selectedUser}
            />
            <div style={{ background: '#fff', margin: 24, padding: 0, minHeight: 280 }}>
              <Table
                columns={columns}
                rowKey={record => record.objectId}
                dataSource={this.state.data}
                pagination={this.state.pagination}
                loading={this.state.loading}
                onChange={this.handleTableChange}
              />
            </div>
          </Col>
        </Row>
      </CleanLayout>
    )
  }
}
